<template>
  <div>
    <section v-if="!exists">
      <section v-if="tripulacao">
        <section v-if="page == 1">
          <div class="row">
            <div class="col-12 centered bold">Checklist de Ambulância</div>
            <div class="col-12 mt-2">
              <span class="bold">Veículo:</span> {{ veiculo.nome_veiculo }}
            </div>
            <div class="col-12 mt-1">
              <span class="bold">Placa:</span>
              {{ veiculo.placa_veiculo }}
            </div>
            <div class="col-12 mt-2">
              <div class="alert alert-warning centered" role="alert">
                Preencha o checklist abaixo e clique em avançar para ir para a
                próxima etapa.
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <label>Km Atual</label>
              <input
                type="tel"
                v-model="checklist.km_atual"
                class="form-control"
              />
            </div>
            <div class="col-12 mt-1">
              <label>Ambulância Lavada?</label>
              <select
                class="form-control"
                v-model="checklist.ambulancia_lavada"
              >
                <option value=""></option>
                <option value="SIM">SIM</option>
                <option value="NÃO">NÃO</option>
              </select>
            </div>
            <div class="col-12 mt-1">
              <label>Nível do Combustível</label>
              <select
                class="form-control"
                v-model="checklist.nivel_combustivel"
              >
                <option value="Vazio/Reserva">Vazio/Reserva</option>
                <option value="1/4">1/4</option>
                <option value="1/2">1/2</option>
                <option value="3/4">3/4</option>
                <option value="Cheio">Cheio</option>
              </select>
            </div>
            <!-- <div class="col-12 mt-1">
            <label
              >Houve abastecimento? quantos litros foram abastecidos?</label
            >
            <input
              type="tel"
              class="form-control"
              v-model="checklist.litros_abastecidos"
            />
          </div>
          <div class="col-12 mt-1">
            <label>Data/Hora Abastecimento</label>
            <input
              type="datetime-local"
              class="form-control"
              v-model="checklist.data_abastecimento"
            />
          </div> -->
            <section
              v-for="group in group_checklist"
              :key="group.id"
              class="mt-2"
            >
              <div class="col-12 title-group">{{ group.titulo }}</div>
              <div
                class="col-12"
                v-for="item in filteredGroup(group.grupo_checklist_id)"
                :key="item.id"
              >
                <div class="row mt-1">
                  <div class="col-6">{{ item.titulo }}</div>

                  <!-- Pergunta do tipo texto -->
                  <div class="col-6" v-if="item.tipo_pergunta == 2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.value"
                    />
                  </div>

                  <!-- Pergunta do tipo número -->
                  <div class="col-6" v-if="item.tipo_pergunta == 3">
                    <input
                      type="tel"
                      class="form-control"
                      v-model="item.value"
                    />
                  </div>

                  <!-- Pergunta do tipo alternativa -->
                  <div class="col-6" v-if="item.tipo_pergunta == 1">
                    <select class="form-control" v-model="item.value">
                      <option value=""></option>
                      <option
                        v-for="resposta in item.itens"
                        :key="resposta.id"
                        :value="resposta.titulo"
                      >
                        {{ resposta.titulo }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <section v-if="page == 2" id="sectionDraw" ref="parentElement">
          <div class="row">
            <div class="col-12 mt-2">
              <div class="alert alert-info centered" role="alert">
                Aqui você pode realizar marcações de inconformidades na viatura
                (amassados, riscos, trincos, etc...), clique na imagem
                exatamente na posição que existe uma não conformidade e atribua
                uma nota a este ponto.
              </div>
            </div>
            <div class="col-md-12">
              <div class="draw" @click="drawDot"></div>
            </div>
            <div class="col-md-12 mt-2 mb-3" align="center">
              <button class="btn btn-info" @click="removeAllPin">
                Remover todas as marcações
              </button>
            </div>
          </div>
        </section>
        <section v-if="page == 3">
          <div class="row">
            <div class="col-12 centered bold">
              Enviar Imagens de Evidência (opcional)
            </div>
          </div>

          <div class="row">
            <div class="col-12" align="center">
              <input
                type="file"
                ref="fileInput"
                @change="handleFileUpload"
                accept="image/x-png, image/gif, image/jpeg"
                style="display: none"
                capture
              />
              <button
                class="btn btn-block btn-addimg"
                @click="$refs.fileInput.click()"
              >
                <i
                  class="fas fa-camera"
                  style="font-size: 18px; margin-bottom: 5px"
                ></i>
                <br />
                Escolha uma foto
              </button>
            </div>
            <div class="col-12 mt-3">
              <div class="row">
                <div
                  v-for="(photo, index) in photos"
                  :key="index"
                  class="col-6"
                >
                  <img
                    :src="getBase64Image(photo)"
                    alt="Imagem"
                    style="
                      max-width: 150px;
                      max-height: 150px;
                      border: 2px solid #222;
                      border-radius: 5px;
                      margin-bottom: 10px;
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <label>Observação: </label>
            <textarea
              v-model="checklist.observacao"
              class="form-control"
              id=""
              cols="20"
              rows="3"
            ></textarea>
          </div>
        </section>

        <div class="row justify-content-center mt-2 mb-4">
          <div class="col-6" align="center" v-if="page > 1">
            <button class="btn btn-primary" @click="page--, verifyPoints()">
              Voltar
            </button>
          </div>
          <div class="col-6" align="center" v-if="page < 3">
            <button class="btn btn-primary" @click="save(1)">Avançar</button>
          </div>
          <div class="col-6" align="center" v-if="page == 3">
            <button class="btn btn-primary" @click="save">
              Salvar Checklist
            </button>
          </div>
        </div>

        <!-- Modal de Motivo -->
        <div
          class="modal fade"
          id="modalMotivoPin"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalMotivoPinTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Selecione uma nota para esta marcação
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label>Selecione uma nota</label>
                <select class="form-control" v-model="point.reason">
                  <option value=""></option>
                  <option value="(R) RISCADO">(R) RISCADO</option>
                  <option value="(Q) QUEBRADO">(Q) QUEBRADO</option>
                  <option value="(F) FALTANTE">(F) FALTANTE</option>
                  <option value="(A)AMASSADO">(A)AMASSADO</option>
                  <option value="(T) TRINCADO">(T) TRINCADO</option>
                  <option value="(I) INOPERANTE">(I) INOPERANTE</option>
                </select>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  @click="points.push(point), pinImage(point.x, point.y)"
                >
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-else>
        <div class="alert alert-danger" role="alert" style="text-align: center">
          Condutor não embarcado.
        </div>
      </section>
    </section>
    <section v-else>
      <div class="alert alert-danger" role="alert" style="text-align: center">
        Checklist já finalizado para este veículo nas últimas 24h
      </div>
    </section>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createCanvas, loadImage } from "canvas";

export default {
  data() {
    return {
      page: 1,
      exists: false,
      equipe: {},
      tripulacao: {},
      checklist: {},
      veiculo: {},
      points: [],
      group_checklist: [],
      itens_checklist: [],
      respostas_checklist: [],
      pin_iterator: 0,
      point: {},
      photos: [],
      isDragging: false,
      fuelWidth: 70,
    };
  },
  computed: {},
  methods: {
    save(is) {
      const self = this;
      let api = self.$store.state.api + "checklists/save";

      self.$loading(true);

      axios
        .post(api, {
          itens: self.itens_checklist,
          veiculo: self.veiculo,
          fotos: self.photos,
          points: self.points,
          checklist: self.checklist,
          page: self.page,
        })
        .then((response) => {
          if (is == 1) {
            self.page++;
            self.$loading(false);
            return;
          }
          self.$loading(false);
          this.$router.push("/condutor/index");
          self.$message("Sucesso", "Checklist finalizado", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    filteredGroup(id) {
      return this.itens_checklist.filter(
        (item) => item.grupo_checklist_id == id
      );
    },
    view(id) {
      const self = this;
      this.$router.push("/visualizar-solicitacao/" + id);
    },
    getEntity: function (id) {
      const self = this;
      let api = self.$store.state.api + "get-checklist";

      self.$loading(true);

      axios
        .post(api, self.filtro)
        .then((response) => {
          self.equipe = response.data.equipe;
          self.tripulacao = response.data.tripulacao;
          self.veiculo = response.data.veiculo;
          self.exists = response.data.exists;

          self.$loading(false);

          if (self.veiculo && !self.exists) {
            self.getGroupChecklist();
            self.getItemsChecklist();
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    getGroupChecklist() {
      const self = this;
      let api = self.$store.state.api + "groupchecklists?paginated=false";

      axios
        .get(api, self.filtro)
        .then((response) => {
          self.group_checklist = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    getItemsChecklist() {
      const self = this;
      let api =
        self.$store.state.api +
        "itemchecklists?paginated=false&with[]=itens&orderByAsc=titulo";

      self.$loading(true);

      axios
        .get(api, self.filtro)
        .then((response) => {
          self.itens_checklist = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    drawDot(event) {
      var parentOffset = this.$refs.parentElement.getBoundingClientRect();
      var mouseX = event.clientX - parentOffset.left;
      var mouseY = event.clientY - parentOffset.top;

      console.log("X: " + mouseX + " - Y: " + mouseY);
      console.log(
        "Left: " + parentOffset.left + " - Right: " + parentOffset.right
      );

      // mouseX = mouse;
      mouseY = mouseY + 85;

      this.point = {
        x: mouseX,
        y: mouseY,
        reason: 1,
      };

      $("#modalMotivoPin").modal("show");
    },

    verifyPoints() {
      const self = this;

      setTimeout(function () {
        self.points.forEach((element) => {
          self.pinImage(element.x, element.y);
        });
      }, 300);
    },

    pinImage(mouseX, mouseY) {
      var color = "#00ff45";
      var size = "25px";
      var div = document.createElement("div");
      div.setAttribute("id", "pin" + this.pin_iterator);
      div.style.position = "absolute";
      div.style.top = mouseY + "px";
      div.style.left = mouseX + "px";
      div.style.width = size;
      div.style.height = size;
      div.style.backgroundColor = color;
      document.getElementById("sectionDraw").appendChild(div);
      this.pin_iterator++;
    },

    removeAllPin() {
      this.points = [];
      while (this.pin_iterator > 0) {
        this.pin_iterator--;
        $("#pin" + this.pin_iterator).remove();
        if (this.pin_iterator < 0) {
          this.pin_iterator = 0;
        }
      }
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = async () => {
        const base64String = reader.result.split(",")[1];

        // Criar um novo elemento de imagem
        const img = new Image();
        img.src = `data:image/jpeg;base64,${base64String}`;

        img.onload = async () => {
          // Calcular novas dimensões
          const newWidth = 800;
          const newHeight = 600;

          // Criar um canvas com as novas dimensões
          const canvas = createCanvas(newWidth, newHeight);
          const ctx = canvas.getContext("2d");

          // Desenhar a imagem no canvas com as novas dimensões
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          // Converter o canvas de volta para base64 (comprimido)
          const compressedBase64 = canvas.toDataURL("image/jpeg", 0.6);

          // Adicionar ao array de fotos
          this.photos.push(compressedBase64);
        };
      };

      reader.readAsDataURL(file);
    },

    getBase64Image(base64String) {
      return `${base64String}`;
    },

    startDrag(event) {
      this.isDragging = true;
      this.updateFuelLevel(event.clientX);
      document.addEventListener("mousemove", this.handleDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    handleDrag(event) {
      if (this.isDragging) {
        this.updateFuelLevel(event.clientX);
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.handleDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    updateFuelLevel(clientX) {
      const indicatorRect = this.$el
        .querySelector(".fuel-indicator")
        .getBoundingClientRect();
      const newWidth =
        Math.max(
          0,
          Math.min(1, (clientX - indicatorRect.left) / indicatorRect.width)
        ) * 100;
      this.fuelWidth = Math.round(newWidth);
    },
  },
  components: { Loading },
  props: {},
  mounted() {
    const self = this;

    self.getEntity();

    setTimeout(function () {
      self.user = self.$store.state.user;
      $("body").css("zoom", "100%");
    }, 200);

    this.$refs.editor.set("selectMode");
  },
};
</script>
<style scoped>
/* CUSTOMIZACOES VUE LOADING */
.custom-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 128, 0);
  backdrop-filter: blur(3px);
  opacity: 0.98;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.custom-loading-spinner {
  width: 100px;
  height: 100px;
  z-index: 10000;
}

/* CUSTOMIZACOES INPUTS */
.custom-input {
  border-radius: 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus {
  z-index: 3;
}

.custom-input-container {
  position: relative;
}

.custom-input {
  padding: 0.375rem 0.75rem;
  height: 2.714rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15);
  width: 100%;
}

.custom-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.botao-cadastrar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.sem-registros {
  position: fixed;
  bottom: 50%;
  width: 100%;
  /* padding-left: 10px;
  padding-right: 10px; */
}

span {
  font-weight: normal;
}

.pisca {
  animation: animate 2.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.draw {
  background-image: url("~@/assets/images/checklist_ambulancia.png");
  background-repeat: no-repeat;
  width: 100% !important;
  background-size: cover;
  height: 130vw;
  border-style: solid;
}

.title-group {
  background-color: #003163;
  color: white;
  width: 100% !important;
  font-size: 16px;
  font-weight: bold;
}

.btn-addimg {
  border: 2px solid #003163;
  padding: 0;
}
.btn-block {
  margin-top: 25px;
  width: 170px;
  padding: 15px;
}

.fuel-indicator {
  width: 200px;
  height: 30px;
  border: 1px solid #000;
  position: relative;
  cursor: pointer;
}

.marks-container {
  display: flex;
  height: 100%;
}

.mark {
  height: 100%;
  flex-grow: 1;
  background-color: #000;
  opacity: 0.5;
}

.fuel-level {
  height: 100%;
  background-color: #36a2eb;
  position: absolute;
  top: 0;
  left: 0;
}
</style>