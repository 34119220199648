import CrudAtendimento from '../views/Condutor/Index'
import Checklist from '../views/Condutor/Checklist'
import View from '../views/Condutor/View'

let routes = {
    path: '/condutor',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/visualizar-solicitacao/:id?',
            name: 'Visualizar-solicitacao',
            component: View
        },
        {
            path: '/condutor/index',
            name: 'list-condutor-index',
            component: CrudAtendimento,
            meta: {
                pageTitle: 'Atendimentos',
                breadcrumb: [
                    {
                        text: 'Atendimentos',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/condutor/checklist',
            name: 'condutor-checklist',
            component: Checklist
        },
    ]
};


export default routes
