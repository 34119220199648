<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12" style="padding-left: 5px; padding-right: 5px">
        <div v-if="tripulante.embarque_ativo > 0">
          <div v-if="tripulante.embarque_ativo == 1 && atendimentos == ''">
            <div
              class="alert alert-dark"
              role="alert"
              style="font-size: 14px; text-align: center"
            >
              Embarcado na equipe: {{ tripulante.equipe_tripulante }} -
              <strong style="color: red">Equipe sem ocorrência</strong>
            </div>
          </div>
        </div>
        <div v-if="tripulante.embarque_ativo > 0">
          <div v-if="tripulante.embarque_ativo == 1 && atendimentos != ''">
            <div
              class="alert alert-success"
              role="alert"
              style="font-size: 14px; text-align: center"
            >
              Embarcado na equipe: {{ tripulante.equipe_tripulante }}
            </div>
          </div>
        </div>
        <div v-if="tripulante.embarque_ativo > 1">
          <div v-if="tripulante.embarque_ativo == 2">
            <div
              class="alert alert-success"
              role="alert"
              style="font-size: 14px; text-align: center"
            >
              Embarcado na equipe: {{ tripulante.equipe_tripulante }}
            </div>
            <div
              class="alert alert-danger pisca"
              role="alert"
              style="font-size: 14px; text-align: center"
            >
              Embarque incorreto
            </div>
          </div>
        </div>
        <div v-if="tripulante.embarque_ativo == 0">
          <div
            class="alert alert-danger"
            role="alert"
            style="text-align: center"
          >
            Condutor não embarcado.
          </div>
        </div>
      </div>
      <div class="col-12" style="padding-left: 5px; padding-right: 5px">
        <div class="col-12 centered mb-3">
          <button class="btn btn-secondary" @click="getEntity">
            <i class="fas fa-sync-alt"></i> Atualizar
          </button>
        </div>
        <div
          @click="view(atendimento.id)"
          class="card card-style"
          v-for="atendimento in atendimentos"
          :key="atendimento.transporte_sanitario_id"
          style="background-color: white; margin-left: 0px; margin-right: 0px"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <h4 class="card-title">
                  #{{ atendimento.numero_solicitacao }}
                </h4>
              </div>
              <div class="col-7 d-flex flex-row-reverse">
                <h4 class="card-title">
                  <i class="fas fa-calendar-alt mr-1"></i
                  >{{ atendimento.data_procedimento_f }}
                </h4>
              </div>
            </div>
            <span style="font-size: 14px" v-if="atendimento.paciente">
              Nome do Paciente:
              <strong style="font-size: 12px">{{
                atendimento.paciente.nome_paciente
              }}</strong>
            </span>
            <br />
            <span
              style="font-size: 14px"
              v-if="atendimento.paciente"
              class="mt-2"
            >
              Motivo do Transporte:
              {{ atendimento.motivo_transporte.motivo_transporte_nome }}
            </span>
            <br />
            <span style="font-size: 14px" v-if="atendimento.paciente">
              Tipo da Viatura:
              {{ atendimento.tipo_transporte.tipo_transporte_nome }}
            </span>
            <br />
            <span style="font-size: 14px" v-if="atendimento.paciente">
              Equipe:
              {{ atendimento.veiculo.veiculo.nome_veiculo }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
export default {
  data() {
    return {
      atendimentos: [],
      tripulante: {
        embarque_ativo: 0,
      },
    };
  },
  computed: {},
  methods: {
    view(id) {
      const self = this;
      const atendimento = self.atendimentos.find((a) => a.id === id);
      if (atendimento) {
        const envioVeiculoId = atendimento.veiculo.envio_veiculo_id;
        let api = self.$store.state.api + "salvar-log";
        axios
          .post(api, { id: id, envio_veiculo_id: envioVeiculoId })
          .then((response) => {
            console.log(response.data);
            self.$router.push("/visualizar-solicitacao/" + id);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.error("Atendimento não encontrado");
      }
    },
    getEntity: function (id) {
      const self = this;
      let api = self.$store.state.api + "get-atendimentos";

      self.$loading(true);

      axios
        .post(api, self.filtro)
        .then((response) => {
          self.atendimentos = response.data.atendimentos;
          self.tripulante = response.data.tripulante;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
  },
  components: { Loading },
  props: {},
  mounted() {
    const self = this;

    self.getEntity();

    setTimeout(function () {
      self.user = self.$store.state.user;
    }, 200);
  },
};
</script>
<style scoped>
/* CUSTOMIZACOES VUE LOADING */
.custom-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(3px);
  opacity: 0.98;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.custom-loading-spinner {
  width: 100px;
  height: 100px;
  z-index: 10000;
}

/* CUSTOMIZACOES INPUTS */
.custom-input {
  border-radius: 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus {
  z-index: 3;
}

.custom-input-container {
  position: relative;
}

.custom-input {
  padding: 0.375rem 0.75rem;
  height: 2.714rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15);
  width: 100%;
}

.custom-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.botao-cadastrar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.sem-registros {
  position: fixed;
  bottom: 50%;
  width: 100%;
  /* padding-left: 10px;
  padding-right: 10px; */
}

span {
  font-weight: normal;
}

.pisca {
  animation: animate 2.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
</style>