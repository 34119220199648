import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import env from '../config/env';
import Router from '../router/'

Vue.use(Vuex)

const baseURL = env.baseURL;
const normalURL = env.normalURL;
const downloadURL = env.downloadURL;

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  state: {
    user: {
      usuario_nome: '',
      foto: '',
      login: '',
      perfil_id: 0,
      usuario_id: 0
    },
    authenticated: false,
    notifications: 0,
    token: '',
    api: baseURL,
    url: normalURL,
    export: downloadURL,
    breadcrumbs: [{ name: "Home", route: "/" }]
  },
  getters: {
    token: state => {
      return state.token;
    },
    authenticated: state => {
      if (state.token == null || state.token == 'null') {
        return false;
      }

      return state.authenticated;
    },
    logo: state => {
      return state.logo;
    },
    user: state => {
      return state.user;
    },
    api: state => {
      return state.api;
    },
    breadcrumbs: state => {
      return state.breadcrumbs;
    }
  },
  mutations: {
    auth(state, token) {

      if (token != null && token != undefined) {
        state.authenticated = true;
        state.token = token;

        localStorage.clear();
        localStorage.setItem('token', token);

        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('token');
        this.commit('profileUpdate');
      }
    },
    updateNotifications(state, data) {
      state.notifications = Number(state.notifications) + 1;
    },
    profileUpdate(state) {
      axios.post(state.api + 'me', {
        token: state.token
      })
        .then(response => {
          state.user.usuario_id = response.data.usuario_id;
          state.user.usuario_nome = response.data.usuario_nome;
          state.user.login = response.data.login;
          state.user.perfil_id = response.data.perfil_id;
          state.user.foto = state.url + "user/image/" + response.data.usuario_id;


          localStorage.setItem('accept_terms', response.data.accept_terms);
          localStorage.setItem('perfil_id', response.data.perfil_id);

          axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('token');

          if (response.data.perfil_id == 20) {
            Router.push('/condutor/index');
          }

        })
        .catch(error => {
          localStorage.clear();

          Router.push('/login');
        });
    },
    updateBreadCrumbs(state, newRoute) {
      if (state.breadcrumbs.length > 1) {
        state.breadcrumbs.pop();
      }
      state.breadcrumbs.push(newRoute)
      // state.breadcrumbs = newRoute;
    }
  },
  actions: {
    initialize(context) {
      const token = localStorage.getItem('token');
      context.commit('auth', token);
    }
  },
})
