<template>
  <div>
    <div class="main-content" v-if="!isLoading">
      <div class="row" id="element-to-convert">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <div class="card-title">Solicitação de Transporte</div>
              <button
                data-toggle="modal"
                data-target="#modalRAS"
                target="_blank"
                class="btn btn-back mr-2"
              >
                <i class="fas fa-file-medical"></i> Inserir Anexo
              </button>
              <div
                class="modal fade"
                id="modalRAS"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modalRASTitle"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        Inserir Anexo
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6">
                          <label>Nome do arquivo</label>
                          <input
                            class="form-control"
                            type="text"
                            v-model="anexo.nome_anexo"
                          />
                        </div>
                        <div class="col-md-6">
                          <label>Anexo</label><br />
                          <input
                            type="file"
                            id="anexo"
                            name="anexo"
                            ref="anexo"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-dismiss="modal"
                        @click="salvarAnexo"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row" style="margin-top: -20px">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12 centered">
                      <button @click="toggleCollapseSolicitacao" class="btn">
                        Exibir Informações da Solicitação
                        <i class="fas fa-chevron-down ml-1"></i>
                      </button>
                      <section v-if="!showCollapseSolicitacao">
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Informações da Solicitação
                            </div>
                          </div>
                        </div>

                        <div
                          class="row mb-1"
                          v-if="
                            configuracoes.classificacao == 1 &&
                            entity.classificacao
                          "
                        >
                          <div class="col-11">
                            <label>Classificação da ocorrência</label>

                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.classificacao.descricao"
                            />
                          </div>

                          <div
                            class="mt-2 classificacao"
                            :style="'background: ' + entity.classificacao.cor"
                          ></div>
                        </div>

                        <div class="row">
                          <div class="col-md-3">
                            <label>Número da Solicitação</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.numero_solicitacao"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Data e Hora da Solicitação</label>
                            <input
                              disabled
                              type="datetime-local"
                              class="form-control"
                              v-model="entity.data_solicitacao"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Data e Hora do Agendamento</label>
                            <input
                              disabled
                              type="datetime-local"
                              class="form-control"
                              v-model="entity.data_procedimento"
                            />
                          </div>

                          <div class="col-md-3">
                            <label>Tipo de Solicitação</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="
                                entity.tipo_transporte.tipo_transporte_nome
                              "
                            />
                          </div>
                        </div>
                        <div class="row mt-1 html2pdf__page-break">
                          <div class="col-md-12">
                            <label>Motivo do Solicitação</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="
                                entity.motivo_transporte.motivo_transporte_nome
                              "
                            />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-12">
                            <label>Observações do Transporte</label>
                            <textarea
                              disabled
                              class="form-control"
                              cols="30"
                              rows="3"
                              v-model="entity.observacao_transporte"
                            ></textarea>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <toggle-button
                              v-model="entity.flag_mandado_judicial"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            É mandado judicial?
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.flag_mandado_judicial"
                        >
                          <div class="col-md-3">
                            <label
                              ><span class="required">*</span> Número do
                              processo</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.mj_numero_processo"
                              disabled
                            />
                          </div>

                          <div class="col-md-3">
                            <label>Data da liberação da vaga</label>
                            <input
                              type="date"
                              class="form-control"
                              v-model="entity.mj_data_liberacao_vaga_f"
                              disabled
                            />
                          </div>
                          <div class="col-md-3">
                            <label> Data da sentença</label>
                            <input
                              type="date"
                              class="form-control"
                              v-model="entity.mj_data_sentenca"
                              disabled
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Juiz</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.mj_juiz"
                              disabled
                            />
                          </div>
                          <div class="col-md-12 mt-1">
                            <label> Resumo da Sentença</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.mj_resumo_sentenca"
                              disabled
                            />
                          </div>
                        </div>
                        <hr />
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <toggle-button
                              v-model="entity.flag_vaga_zero"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            É vaga zero?
                          </div>
                        </div>
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">Solicitante</div>
                          </div>
                        </div>

                        <div
                          class="row"
                          v-if="
                            configuracoes.setor_solicitacao == 1 &&
                            configuracoes.telefone_solicitacao == 1 &&
                            configuracoes.profissional_solicitacao == 1
                          "
                        >
                          <div class="col-md-4">
                            <label
                              ><span class="required">*</span> Responsável pela
                              abertura da solicitação</label
                            >
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.profissional_nome"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="
                                entity.solicitante.unidade_solicitante_setor
                              "
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                              v-model="
                                entity.solicitante.unidade_solicitante_telefone
                              "
                            />
                          </div>
                          <!-- <div class="col-md-4" @click="getCbo">
                            <label>CBO</label>
                            <v-select
                              @click="getCbo"
                              :options="cbos"
                              label="cbo_nome"
                              v-model="entity.cbo_id"
                              :reduce="(cbo) => cbo.cbo_id"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de CBO's
                              </div>
                            </v-select>
                          </div> -->
                          <div class="col-md-4">
                            <label>Nome do médico solicitante</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.medico_solicitante"
                            />
                          </div>
                          <!-- <div class="col-md-4 mt-1">
                            <label>ID Funcional</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.id_funcional"
                            />
                          </div> -->
                          <div class="col-md-4">
                            <label>Número do Conselho</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.numero_conselho"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Conselho de Classe</label>
                            <v-select
                              disabled
                              :options="conselho_classe"
                              label="nome"
                              v-model="entity.solicitante.conselho_classe"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de conselhos
                              </div>
                            </v-select>
                          </div>
                          <div class="col-md-6 mt-1">
                            <label>Unidade Solicitante</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              :value="
                                entity.solicitante.unidade
                                  ? entity.solicitante.unidade
                                      .unidade_saude_nome
                                  : ''
                              "
                            />
                          </div>
                        </div>

                        <div
                          class="row"
                          v-if="
                            configuracoes.setor_solicitacao == 0 &&
                            configuracoes.telefone_solicitacao == 0 &&
                            configuracoes.profissional_solicitacao == 0
                          "
                        >
                          <div class="col-md-4">
                            <label
                              ><span class="required">*</span> Nome do
                              Profissional</label
                            >
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              v-model="entity.solicitante.profissional_nome"
                            />
                          </div>
                          <div
                            class="col-md-4"
                            v-if="entity.solicitante.cbo_id"
                          >
                            <label>CBO</label>
                            <input
                              v-if="entity.solicitante.cbo"
                              type="text"
                              disabled
                              class="form-control"
                              v-model="entity.solicitante.cbo.cbo_nome"
                            />
                            <input
                              v-else
                              type="text"
                              disabled
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>CNS</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.profissional_cns"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>ID Funcional</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.id_funcional"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Número do Conselho</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.numero_conselho"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Conselho de Classe</label>
                            <v-select
                              disabled
                              :options="conselho_classe"
                              label="nome"
                              v-model="entity.solicitante.conselho_classe"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de conselhos
                              </div>
                            </v-select>
                          </div>
                          <div class="col-md-6 mt-1">
                            <label>Unidade Solicitante</label>
                            <v-select
                              disabled
                              :options="unidade_saude"
                              :reduce="(unidade_saude) => unidade_saude.id"
                              label="unidade_saude_nome"
                              v-model="
                                entity.solicitante.unidade_solicitante_id
                              "
                            ></v-select>
                          </div>
                          <div class="col-md-3 mt-1">
                            <label>Setor</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="
                                entity.solicitante.unidade_solicitante_setor
                              "
                            />
                          </div>
                          <div class="col-md-3 mt-1">
                            <label>Telefone</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                              v-model="
                                entity.solicitante.unidade_solicitante_telefone
                              "
                            />
                          </div>
                        </div>
                        <div class="html2pdf__page-break"></div>
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Endereço de Origem
                            </div>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Tipo de Endereço</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="
                                entity.origem.tipo_estabelecimento
                                  .tipo_estabelecimento_nome
                              "
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.origem.tipo_estabelecimento_id == 6"
                        >
                          <div class="col-md-6">
                            <label>Unidade de Origem</label>
                            <input
                              type="text"
                              v-model="entity.origem.estabelecimento_nome"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-2">
                            <label>CEP da Origem</label>
                            <input
                              disabled
                              v-model="entity.origem.cep"
                              type="tel"
                              class="form-control"
                              v-mask="'99999-999'"
                            />
                          </div>
                          <div class="col-md-5">
                            <label>Logradouro</label>
                            <input
                              disabled
                              v-model="entity.origem.endereco"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-2">
                            <label>Número</label>
                            <input
                              disabled
                              id="inputNumero"
                              v-model="entity.origem.numero"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Bairro</label>
                            <input
                              disabled
                              v-model="entity.origem.bairro"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-2 mt-1">
                            <label>Estado</label>
                            <v-select
                              disabled
                              :options="ufs"
                              :reduce="(ufs) => ufs.id"
                              :label="'uf_nome'"
                              v-on:input="getEstadoMunicipioOrigem"
                              v-model="entity.origem.estado.uf_nome"
                              class="vselect"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Município</label>
                            <v-select
                              disabled
                              :options="municipios_origem"
                              :reduce="(municipios) => municipios.id"
                              :label="'municipio_nome'"
                              v-model="entity.origem.municipio.municipio_nome"
                              class="vselect"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mt-1">
                            <label>Complemento</label>
                            <input
                              disabled
                              v-model="entity.origem.complemento"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Ponto de Referência</label>
                            <input
                              disabled
                              v-model="entity.origem.ponto_referencia"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Telefone</label>
                            <input
                              disabled
                              v-model="entity.origem.telefone"
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                        </div>

                        <div
                          class="row"
                          v-if="configuracoes.dificuldade_acesso"
                        >
                          <div class="col-md-6 mt-1">
                            <label
                              ><span class="required">*</span> Possui
                              dificuldade de acesso ao local?</label
                            >
                            <select
                              class="form-control"
                              v-model="entity.origem.dificuldade_acesso"
                              disabled
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="entity.origem.dificuldade_acesso == 'SIM'"
                          >
                            <label><span class="required">*</span> Qual?</label>
                            <input
                              v-model="
                                entity.origem.dificuldade_acesso_descricao
                              "
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.origem.tipo_estabelecimento_id == 6"
                        >
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input
                              v-model="entity.origem.setor"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Pavimento</label>
                            <input
                              v-model="entity.origem.pavimento"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Leito</label>
                            <input
                              v-model="entity.origem.leito"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="html2pdf__page-break"></div>
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Endereço de Destino
                            </div>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Tipo de Endereço</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="
                                entity.destino.tipo_estabelecimento
                                  .tipo_estabelecimento_nome
                              "
                            />
                          </div>
                          <div class="col-md-6">
                            <label>Tipo de Destino</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.tipo_destino"
                            >
                              <option value="Municipal">Municipal</option>
                              <option value="Intermunicipal">
                                Intermunicipal
                              </option>
                            </select>
                          </div>
                        </div>

                        <div
                          class="row mt-1"
                          v-if="entity.destino.tipo_estabelecimento_id == 6"
                        >
                          <div class="col-md-6">
                            <label>Unidade de Destino</label>
                            <input
                              type="text"
                              v-model="entity.destino.estabelecimento_nome"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-2">
                            <label>CEP do Destino</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              id="cepUnidade"
                              v-mask="'99999-999'"
                              v-model="entity.destino.cep"
                            />
                          </div>
                          <div class="col-md-5">
                            <label>Logadouro</label>
                            <input
                              disabled
                              v-model="entity.destino.endereco"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-2">
                            <label>Número</label>
                            <input
                              disabled
                              id="inputNumeroDestino"
                              v-model="entity.destino.numero"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Bairro</label>
                            <input
                              disabled
                              v-model="entity.destino.bairro"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-2 mt-1">
                            <label>Estado</label>
                            <v-select
                              disabled
                              :options="ufs"
                              :reduce="(ufs) => ufs.id"
                              :label="'uf_nome'"
                              v-on:input="getEstadoMunicipioDestino"
                              v-model="entity.destino.estado.uf_nome"
                              class="vselect"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Município</label>
                            <v-select
                              disabled
                              :options="municipios_destino"
                              :reduce="(municipios) => municipios.id"
                              :label="'municipio_nome'"
                              v-model="entity.destino.municipio.municipio_nome"
                              class="vselect"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mt-1">
                            <label>Complemento</label>
                            <input
                              disabled
                              v-model="entity.destino.complemento"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Ponto de Referência</label>
                            <input
                              disabled
                              v-model="entity.destino.ponto_referencia"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Telefone</label>
                            <input
                              disabled
                              v-model="entity.destino.telefone"
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                        </div>

                        <div
                          class="row mt-1"
                          v-if="entity.destino.tipo_estabelecimento_id == 6"
                        >
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input
                              v-model="entity.destino.setor"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Pavimento</label>
                            <input
                              v-model="entity.destino.pavimento"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Leito</label>
                            <input
                              v-model="entity.destino.leito"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-12">
                            <label
                              >Nome do Profissional Responsável pela
                              Autorização</label
                            >
                            <input
                              v-model="
                                entity.destino.nome_profissional_autorizacao
                              "
                              disabled
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <div class="custom-control custom-switch">
                              <input
                                disabled
                                autocomplete="new-password"
                                v-model="entity.flag_retorna_origem"
                                placeholder=""
                                type="checkbox"
                                class="custom-control-input"
                                id="customSwitch1"
                              />
                              <label
                                class="custom-control-label"
                                for="customSwitch1"
                                >Retorna para Origem?</label
                              >
                            </div>
                          </div>
                        </div>
                      </section>

                      <div class="html2pdf__page-break"></div>
                      <hr />
                      <button @click="toggleCollapse" class="btn">
                        Exibir Informações do Paciente
                        <i class="fas fa-chevron-down ml-1"></i>
                      </button>
                      <section v-if="!showCollapse">
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Informações do Paciente
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <label>CPF</label>
                            <input
                              disabled
                              :value="
                                entity.paciente ? entity.paciente.cpf : ''
                              "
                              type="text"
                              class="form-control"
                              v-mask="'999.999.999-99'"
                            />
                          </div>
                          <div class="col-md-6">
                            <label>CNS</label>
                            <input
                              disabled
                              :value="
                                entity.paciente ? entity.paciente.cns : ''
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <label>Nome</label>
                            <input
                              disabled
                              v-model="entity.paciente.nome_paciente"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Nome Social</label>
                            <input
                              disabled
                              v-model="entity.paciente.nome_social"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Gênero</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.paciente.sexo"
                            >
                              <option value="M">MASCULINO</option>
                              <option value="F">FEMININO</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <label>Data de Nascimento</label>
                            <input
                              disabled
                              v-model="entity.paciente.data_nascimento"
                              type="date"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input
                              disabled
                              v-model="entity.paciente.telefone"
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Cor/Raça</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.paciente.raca"
                            >
                              <option value="BRANCA">BRANCA</option>
                              <option value="PRETA">PRETA</option>
                              <option value="PARDA">PARDA</option>
                              <option value="AMARELA">AMARELA</option>
                              <option value="INDÍGENA">INDÍGENA</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Nome do Pai</label>
                            <input
                              disabled
                              v-model="entity.paciente.nome_pai"
                              type="tel"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-6">
                            <label>Nome da Mãe</label>
                            <input
                              disabled
                              v-model="entity.paciente.nome_mae"
                              type="tel"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <label
                              >Possui dificuldade de acesso ao entrar ou sair do
                              domicílio?</label
                            >
                            <select
                              disabled
                              class="form-control"
                              v-model="
                                entity.paciente.dificuldade_acesso_domicilio
                              "
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="
                              entity.paciente.dificuldade_acesso_domicilio ==
                              'SIM'
                            "
                          >
                            <label>Qual?</label>
                            <input
                              disabled
                              v-model="
                                entity.paciente
                                  .dificuldade_acesso_domicilio_descricao
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <label
                              >Possui dificuldade de acesso ao entrar ou sair do
                              paciente?</label
                            >
                            <select
                              disabled
                              class="form-control"
                              v-model="
                                entity.paciente.dificuldade_acesso_paciente
                              "
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="
                              entity.paciente.dificuldade_acesso_paciente ==
                              'SIM'
                            "
                          >
                            <label>Qual?</label>
                            <input
                              disabled
                              v-model="
                                entity.paciente
                                  .dificuldade_acesso_paciente_descricao
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <label>Paciente possui acompanhante?</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.possui_acompanhante"
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="entity.possui_acompanhante == 'SIM'"
                          >
                            <label>Tipo do Acompanhante</label>
                            <input
                              disabled
                              :value="
                                entity.acompanhante.tipo_acompanhante
                                  ? entity.acompanhante.tipo_acompanhante
                                      .tipo_acompanhante_nome
                                  : ''
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.possui_acompanhante == 'SIM'"
                        >
                          <div class="col-md-4">
                            <label>Nome</label>
                            <input
                              disabled
                              v-model="entity.acompanhante.acompanhante_nome"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input
                              disabled
                              v-model="
                                entity.acompanhante.acompanhante_telefone
                              "
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>CPF</label>
                            <input
                              disabled
                              :value="
                                entity.acompanhante
                                  ? entity.acompanhante.acompanhante_cpf
                                  : ''
                              "
                              type="tel"
                              class="form-control"
                              v-mask="'999.999.999-99'"
                            />
                          </div>
                        </div>
                      </section>

                      <div class="html2pdf__page-break"></div>
                      <hr />

                      <button @click="toggleCollapseCondicoes" class="btn">
                        Exibir Condições Clínicas do Paciente
                        <i class="fas fa-chevron-down ml-1"></i>
                      </button>
                      <section v-if="!showCollapseCondicoes">
                        <section
                          v-if="entity.tipo_transporte.tipo_solicitacao != 1"
                        >
                          <div class="row mt-3 mb-2">
                            <div class="col-md-12">
                              <div class="b-card-subtitle">
                                Condições Clínicas do Paciente
                              </div>
                            </div>
                          </div>

                          <div
                            class="row mt-3"
                            v-if="!configuracoes.sinais_vitais"
                          >
                            <div class="col-md-12">
                              <h3>Antropometria</h3>
                            </div>

                            <div class="col-md-2">
                              <label for="">Peso</label>
                              <input
                                type="number"
                                class="form-control"
                                id=""
                                v-model="entity.paciente.peso"
                                disabled
                              />
                            </div>
                            <div class="col-md-2">
                              <label for="">Altura</label>
                              <input
                                type="text"
                                class="form-control"
                                id=""
                                v-model="entity.paciente.altura"
                                v-mask="'#.##'"
                                disabled
                              />
                            </div>
                            <div class="col-md-2">
                              <label for="">IMC</label>
                              <input
                                disabled
                                type="text"
                                class="form-control"
                                :value="imc ? imc : ''"
                              />
                            </div>
                            <div class="col-ml-1" style="margin-top: 5px">
                              <table class="tabela-imc">
                                <tr>
                                  <th
                                    v-if="
                                      entity.paciente.peso &&
                                      entity.paciente.altura &&
                                      imc
                                    "
                                  >
                                    Classificação
                                  </th>
                                  <th
                                    v-if="
                                      entity.paciente.peso &&
                                      entity.paciente.altura &&
                                      imc
                                    "
                                  >
                                    IMC
                                  </th>
                                </tr>
                                <tr
                                  style="
                                    background-color: #ffff00;
                                    color: #000000;
                                  "
                                  v-if="
                                    imc < 18.5 &&
                                    entity.paciente.peso &&
                                    entity.paciente.altura
                                  "
                                >
                                  <td>Abaixo do peso</td>
                                  <td>Menor que 18,5</td>
                                </tr>
                                <tr
                                  style="
                                    background-color: #add8e6;
                                    color: #000000;
                                  "
                                  v-if="
                                    imc >= 18.5 &&
                                    imc <= 24.9 &&
                                    entity.paciente.peso &&
                                    entity.paciente.altura
                                  "
                                >
                                  <td>Peso normal</td>
                                  <td>18,5 - 24,9</td>
                                </tr>
                                <tr
                                  style="
                                    background-color: #ffa500;
                                    color: #000000;
                                  "
                                  v-if="
                                    imc >= 25 &&
                                    imc <= 29.9 &&
                                    entity.paciente.peso &&
                                    entity.paciente.altura
                                  "
                                >
                                  <td>Sobrepeso</td>
                                  <td>25 - 29,9</td>
                                </tr>
                                <tr
                                  style="
                                    background-color: #ff4500;
                                    color: #000000;
                                  "
                                  v-if="
                                    imc >= 30 &&
                                    imc <= 34.9 &&
                                    entity.paciente.peso &&
                                    entity.paciente.altura
                                  "
                                >
                                  <td>Obesidade grau 1</td>
                                  <td>30 - 34,9</td>
                                </tr>
                                <tr
                                  style="
                                    background-color: #ff0000;
                                    color: #000000;
                                  "
                                  v-if="
                                    imc >= 35 &&
                                    imc <= 39.9 &&
                                    entity.paciente.peso &&
                                    entity.paciente.altura
                                  "
                                >
                                  <td>Obesidade grau 2</td>
                                  <td>35 - 39,9</td>
                                </tr>
                                <tr
                                  style="
                                    background-color: #e30505;
                                    color: #000000;
                                  "
                                  v-if="
                                    imc >= 40 &&
                                    entity.paciente.peso &&
                                    entity.paciente.altura
                                  "
                                >
                                  <td>Obesidade grau 3</td>
                                  <td>Maior ou igual a 40</td>
                                </tr>
                              </table>
                            </div>
                            <div class="col-md-12 mt-1">
                              <span
                                v-if="imc >= 30"
                                style="font-weight: bold; color: red"
                                >*Indicação de Transporte Bariatrico</span
                              >
                            </div>
                          </div>

                          <div
                            class="row mb-1"
                            v-if="configuracoes.sinais_vitais == 1"
                          >
                            <div class="col-md-4">
                              <label for="">Estado Neurológico</label>
                              <select
                                class="form-control"
                                v-model="entity.paciente.estado_neurologico"
                                disabled
                              >
                                <option value="Alerta">Alerta</option>
                                <option value="Sonolento">Sonolento</option>
                                <option value="Torporoso">Torporoso</option>
                                <option value="Sedado">Sedado</option>
                                <option value="Inconsciente">
                                  Inconsciente
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="row"
                            v-if="configuracoes.sinais_vitais == 1"
                          >
                            <div class="col-md-2">
                              <label for="">Glicemia</label>
                              <input
                                type="number"
                                placeholder="Máximo de 1000"
                                class="form-control"
                                v-model="entity.paciente.glicemia"
                                disabled
                              />
                            </div>
                            <div class="col-md-3" style="margin-top: 30px">
                              <span
                                class="boxSelecionavel"
                                :class="
                                  entity.paciente.glicemia_tipo == 'Em Jejum'
                                    ? 'ativo'
                                    : ''
                                "
                                >Em Jejum</span
                              >
                              <span
                                class="boxSelecionavel"
                                :class="
                                  entity.paciente.glicemia_tipo ==
                                  'Pós-prandial'
                                    ? 'ativo'
                                    : ''
                                "
                                >Pós-prandial</span
                              >
                            </div>
                          </div>
                          <div
                            class="row mt-4"
                            v-if="configuracoes.sinais_vitais == 1"
                          >
                            <div class="col-md-12">
                              <h3>Sinais vitais</h3>
                            </div>
                            <div class="col-md-12 mt-1">
                              <div class="row">
                                <div class="col-md-4 bold">
                                  Pressão arterial
                                </div>
                                <div class="col-md-2 bold">Temperatura</div>
                                <div class="col-md-2 bold">FR</div>
                                <div class="col-md-2 bold">FC</div>
                                <div class="col-md-2 bold"></div>
                              </div>
                              <div class="row">
                                <div class="col-md-2">
                                  <label for="pa_sistolica"
                                    >Sist. (mmHg):</label
                                  >
                                  <input
                                    type="number"
                                    id="pa_sistolica"
                                    v-model.number="
                                      entity.paciente.pa_sistolica
                                    "
                                    class="form-control"
                                    placeholder="Máximo de 300"
                                    disabled
                                  />
                                </div>
                                <div class="col-md-2">
                                  <label for="">Diast. (mmHg)</label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="entity.paciente.pa_diastolica"
                                    placeholder="Máximo de 300"
                                    disabled
                                  />
                                </div>
                                <div class="col-md-2">
                                  <label for="">C°</label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="entity.paciente.temperatura"
                                    placeholder="Máximo de 60"
                                    disabled
                                  />
                                </div>
                                <div class="col-md-2">
                                  <label for="">(rpm)</label>
                                  <input
                                    type="number"
                                    disabled
                                    placeholder="Máximo de 300"
                                    class="form-control"
                                    v-model="entity.paciente.fr"
                                  />
                                </div>
                                <div class="col-md-2">
                                  <label for="">(bpm)</label>
                                  <input
                                    type="number"
                                    disabled
                                    placeholder="Máximo de 300"
                                    class="form-control"
                                    v-model="entity.paciente.fc"
                                  />
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-md-2">
                                  <label for="">Saturação</label>
                                  <input
                                    type="number"
                                    disabled
                                    placeholder="Máximo de 100"
                                    class="form-control"
                                    v-model="entity.paciente.saturacao"
                                  />
                                </div>
                                <div class="col-md-5" style="margin-top: 30px">
                                  <span
                                    class="boxSelecionavel"
                                    :class="
                                      entity.paciente.saturacao_tipo ==
                                      'Ar Atmosférico'
                                        ? 'ativo'
                                        : ''
                                    "
                                    >Ar Atmosférico</span
                                  >
                                  <span
                                    class="boxSelecionavel"
                                    :class="
                                      entity.paciente.saturacao_tipo ==
                                      'Em terapia de O2'
                                        ? 'ativo'
                                        : ''
                                    "
                                    >Em terapia de O2</span
                                  >
                                </div>
                              </div>
                              <div class="row mt-2" v-if="diffIdadeDias <= 29">
                                <div class="col-md-2">
                                  <label for="">APGAR (1 minuto)</label>
                                  <input
                                    type="number"
                                    disabled
                                    placeholder="Máximo de 10"
                                    class="form-control"
                                    v-model="entity.paciente.apgar_1"
                                  />
                                </div>
                                <div class="col-md-2">
                                  <label for="">APGAR (5 minutos)</label>
                                  <input
                                    type="number"
                                    disabled
                                    placeholder="Máximo de 10"
                                    class="form-control"
                                    v-model="entity.paciente.apgar_2"
                                  />
                                </div>
                              </div>
                              <div class="row mt-2" v-if="diffIdadeDias <= 29">
                                <div class="col-md-2">
                                  <label for="">Idade Gestacional</label>
                                  <input
                                    type="number"
                                    disabled
                                    placeholder="Máximo de 45"
                                    class="form-control"
                                    v-model="entity.paciente.idade_gestacional"
                                  />
                                </div>
                              </div>
                              <div class="row mt-3">
                                <div class="col-md-12">
                                  <h3>Antropometria</h3>
                                </div>

                                <div class="col-md-2">
                                  <label for="">Peso</label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    id=""
                                    v-model="entity.paciente.peso"
                                    disabled
                                  />
                                </div>
                                <div class="col-md-2">
                                  <label for="">Altura</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id=""
                                    v-model="entity.paciente.altura"
                                    disabled
                                    v-mask="'#.##'"
                                  />
                                </div>
                                <div class="col-md-2">
                                  <label for="">IMC</label>
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    :value="imc ? imc : ''"
                                  />
                                </div>
                                <div class="col-ml-1" style="margin-top: 5px">
                                  <table class="tabela-imc">
                                    <tr>
                                      <th
                                        v-if="
                                          entity.paciente.peso &&
                                          entity.paciente.altura &&
                                          imc
                                        "
                                      >
                                        Classificação
                                      </th>
                                      <th
                                        v-if="
                                          entity.paciente.peso &&
                                          entity.paciente.altura &&
                                          imc
                                        "
                                      >
                                        IMC
                                      </th>
                                    </tr>
                                    <tr
                                      style="
                                        background-color: #ffff00;
                                        color: #000000;
                                      "
                                      v-if="
                                        imc < 18.5 &&
                                        entity.paciente.peso &&
                                        entity.paciente.altura
                                      "
                                    >
                                      <td>Abaixo do peso</td>
                                      <td>Menor que 18,5</td>
                                    </tr>
                                    <tr
                                      style="
                                        background-color: #add8e6;
                                        color: #000000;
                                      "
                                      v-if="
                                        imc >= 18.5 &&
                                        imc <= 24.9 &&
                                        entity.paciente.peso &&
                                        entity.paciente.altura
                                      "
                                    >
                                      <td>Peso normal</td>
                                      <td>18,5 - 24,9</td>
                                    </tr>
                                    <tr
                                      style="
                                        background-color: #ffa500;
                                        color: #000000;
                                      "
                                      v-if="
                                        imc >= 25 &&
                                        imc <= 29.9 &&
                                        entity.paciente.peso &&
                                        entity.paciente.altura
                                      "
                                    >
                                      <td>Sobrepeso</td>
                                      <td>25 - 29,9</td>
                                    </tr>
                                    <tr
                                      class="obesidade1"
                                      v-if="
                                        imc >= 30 &&
                                        imc <= 34.9 &&
                                        entity.paciente.peso &&
                                        entity.paciente.altura
                                      "
                                    >
                                      <td>Obesidade grau 1</td>
                                      <td>30 - 34,9</td>
                                    </tr>
                                    <tr
                                      class="obesidade2"
                                      v-if="
                                        imc >= 35 &&
                                        imc <= 39.9 &&
                                        entity.paciente.peso &&
                                        entity.paciente.altura
                                      "
                                    >
                                      <td>Obesidade grau 2</td>
                                      <td>35 - 39,9</td>
                                    </tr>
                                    <tr
                                      class="obesidade3"
                                      v-if="
                                        imc >= 40 &&
                                        entity.paciente.peso &&
                                        entity.paciente.altura
                                      "
                                    >
                                      <td>Obesidade grau 3</td>
                                      <td>Maior ou igual a 40</td>
                                    </tr>
                                  </table>
                                </div>
                                <div class="col-md-12 mt-1">
                                  <span
                                    v-if="imc >= 30"
                                    style="font-weight: bold; color: red"
                                    >*Indicação de Transporte Bariatrico</span
                                  >
                                </div>
                              </div>
                              <div class="html2pdf__page-break"></div>
                              <div class="row mt-4">
                                <div class="col-md-4">
                                  <label for="">Suporte de O²</label>
                                  <select
                                    class="form-control"
                                    v-model="entity.paciente.suporte_o2"
                                    disabled
                                  >
                                    <option value="Catete Tipo Óculos">
                                      Catete Tipo Óculos
                                    </option>
                                    <option value="Mascara Reservatório">
                                      Mascara Reservatório
                                    </option>
                                    <option value="V.M">V.M</option>
                                    <option value="HOOD">HOOD</option>
                                    <option value="Cpap Nasal">
                                      Cpap Nasal
                                    </option>
                                  </select>
                                </div>
                                <div class="col-md-4">
                                  <label for="">Quantos litros de O²?</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="entity.paciente.litros_o2"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-md-4">
                                  <label for="">Tipos de Acesso Venoso</label>
                                  <select
                                    class="form-control"
                                    v-model="entity.paciente.acesso_venoso"
                                    disabled
                                  >
                                    <option value="MSD">MSD</option>
                                    <option value="MSE">MSE</option>
                                    <option value="Jugular">Jugular</option>
                                    <option value="Subclávia">Subclávia</option>
                                    <option value="Cefálica">Cefálica</option>
                                    <option value="Umbilical">Umbilical</option>
                                    <option value="Acesso Femoral">
                                      Acesso Femoral
                                    </option>
                                    <option value="Outros">Outros</option>
                                  </select>
                                </div>
                                <div
                                  class="col-md-8"
                                  v-if="
                                    entity.paciente.acesso_venoso == 'Outros'
                                  "
                                >
                                  <label for="">Qual?</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="
                                      entity.paciente.acesso_venoso_outros
                                    "
                                    disabled
                                  />
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-md-4">
                                  <toggle-button
                                    v-model="entity.paciente.incubadora"
                                    color="#15488A"
                                    :height="25"
                                    disabled
                                  />
                                  Transporte em Incubadora?
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-md-3">
                                  <toggle-button
                                    v-model="entity.paciente.flg_bomba_infusora"
                                    color="#15488A"
                                    :height="25"
                                    disabled
                                  />
                                  Uso de Bomba Infusora?
                                </div>
                                <div
                                  class="col-md-4"
                                  v-if="entity.paciente.flg_bomba_infusora"
                                >
                                  <label for="">Quantidade</label>
                                  <select
                                    class="form-control"
                                    v-model="entity.paciente.bomba_infusora"
                                    disabled
                                  >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                  </select>
                                </div>
                                <div
                                  class="col-md-12"
                                  v-if="entity.paciente.flg_bomba_infusora"
                                >
                                  <label for="">Medicamentos</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="
                                      entity.paciente
                                        .bomba_infusora_medicamentos
                                    "
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-md-4">
                                  <label>Outros Dispositivos</label>
                                  <select
                                    class="form-control"
                                    v-model="
                                      entity.paciente.outros_dispositivos
                                    "
                                    disabled
                                  >
                                    <option value="Cateter Vesical">
                                      Cateter Vesical
                                    </option>
                                    <option value="Cateter Nasogástrico">
                                      Cateter Nasogástrico
                                    </option>
                                    <option value="Cateter Umbilical ">
                                      Cateter Umbilical
                                    </option>
                                    <option value="Dreno">Dreno</option>
                                    <option value="Ostomia">Ostomia</option>
                                    <option value="PICC">PICC</option>
                                    <option value="Todos">Todos</option>
                                  </select>
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-md-2">
                                  <toggle-button
                                    v-model="entity.paciente.flg_alergias"
                                    color="#15488A"
                                    :height="25"
                                    disabled
                                  />
                                  Alergias
                                </div>
                                <div
                                  class="col-md-8"
                                  v-if="entity.paciente.flg_alergias"
                                >
                                  <label for="">Qual?</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="entity.paciente.alergias"
                                    disabled
                                  />
                                </div>
                              </div>
                              <!-- <div class="row mt-2">
                                <div class="col-md-2">
                                  <toggle-button
                                    v-model="entity.paciente.covid"
                                    color="#15488A"
                                    :height="25"
                                    disabled
                                  />
                                  Covid-19
                                </div>
                              </div>
                              <div
                                class="row mt-2"
                                v-if="entity.paciente.covid"
                              >
                                <div class="col-md-2">
                                  <label for="">Isolamento</label>
                                  <div class="form-check">
                                    <input
                                      v-model="
                                        entity.paciente.covid_isolamento_contato
                                      "
                                      class="form-check-input"
                                      type="checkbox"
                                      value="true"
                                      id="Contato"
                                      disabled
                                    />
                                    <label
                                      class="form-check-label"
                                      for="Contato"
                                      style="font-size: 16px"
                                    >
                                      Contato
                                    </label>
                                  </div>
                                  <div class="form-check">
                                    <input
                                      v-model="
                                        entity.paciente
                                          .covid_isolamento_respiratorio
                                      "
                                      class="form-check-input"
                                      type="checkbox"
                                      value="true"
                                      id="Respiratório"
                                      disabled
                                    />
                                    <label
                                      class="form-check-label"
                                      for="Respiratório"
                                      style="font-size: 16px"
                                    >
                                      Respiratório
                                    </label>
                                  </div>
                                </div>
                              </div> -->

                              <div
                                class="row mt-2"
                                v-if="configuracoes.sinais_vitais == 1"
                              >
                                <div class="col-md-6">
                                  <label>Precauções</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    disabled
                                    v-model="entity.paciente.precaucao"
                                  />
                                </div>
                              </div>
                              <!--  <div class="col-md-3">
                                <label for="">Acesso Venoso Periférico</label>
                                <input
                                  type="number"
                                  placeholder="Local"
                                  class="form-control"
                                  v-model="
                                    entity.paciente.acesso_venoso_periferico
                                  "
                                />
                              </div>
                              <div class="col-md-3">
                                <label for="">Acesso Venoso Profundo</label>
                                <input
                                  type="number"
                                  placeholder="Local"
                                  class="form-control"
                                  v-model="
                                    entity.paciente.acesso_venoso_profundo
                                  "
                                />
                              </div>
                              <div class="col-md-3">
                                <label for="">Bomba Infusora</label>
                                <input
                                  type="number"
                                  placeholder="Quantidade"
                                  class="form-control"
                                  v-model="entity.paciente.bomba_infusora"
                                />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3">
                                <label for="">Escala de Glasgow</label>
                                <input
                                  data-toggle="modal"
                                  data-target="#glasgowModalCenter"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  id=""
                                  v-model="glasgowCount"
                                />
                              </div>
                              <div class="col-md-9" style="margin-top: 30px">
                                <span v-html="glasgowLabel"></span>
                              </div>
                            </div> -->
                            </div>
                          </div>
                          <div class="html2pdf__page-break"></div>

                          <div
                            class="row mb-2 mt-3"
                            v-if="entity.paciente.ficha_clinica.length > 0"
                          >
                            <div class="col-md-12">
                              <div class="b-card-subtitle">Ficha Clínica</div>
                            </div>
                          </div>
                          <div
                            class="row mt-2 mb-2"
                            v-if="entity.paciente.ficha_clinica.length > 0"
                          >
                            <div class="col-md-12">
                              <table
                                class="table table-bordered"
                                style="width: 100%"
                              >
                                <thead>
                                  <tr>
                                    <th scope="col" style="width: 100%">
                                      Descrição
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="fic in entity.paciente.ficha_clinica"
                                    :key="fic.ficha_clinica_id"
                                  >
                                    <td>
                                      {{ fic.ficha_clinica_id }} -
                                      {{
                                        fic.avaliacao_clinica
                                          .tipo_avaliacao_clinica_nome
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-5 mt-1">
                              <label
                                >Paciente depende de auxilio para se
                                locomover?</label
                              >
                              <select
                                disabled
                                class="form-control"
                                v-model="entity.depende_auxilio"
                              >
                                <option value="SIM">Sim</option>
                                <option value="NÃO">Não</option>
                              </select>
                            </div>
                            <div
                              class="col-md-3 mt-1"
                              v-if="entity.depende_auxilio == 'SIM'"
                            >
                              <label>Qual?</label>
                              <select
                                disabled
                                class="form-control"
                                v-model="entity.tipo_auxilio_locomocao_id"
                              >
                                <option
                                  v-for="tipo in tipos_auxilio_locomocao"
                                  :key="tipo.tipo_auxilio_locomocao_id"
                                  :value="tipo.tipo_auxilio_locomocao_id"
                                >
                                  {{ tipo.tipo_auxilio_locomocao_nome }}
                                </option>
                              </select>
                            </div>
                            <div
                              class="col-md-3 mt-1"
                              v-if="entity.tipo_auxilio_locomocao_id == '6'"
                            >
                              <label
                                ><span class="required">*</span>
                                Especifique</label
                              >

                              <input
                                disabled
                                type="text"
                                class="form-control"
                                v-model="entity.outro_tipo_auxilio_locomocao"
                              />
                            </div>
                          </div>
                          <div class="row mb-2 mt-3">
                            <div class="col-md-12">
                              <div class="b-card-subtitle">CID's</div>
                            </div>
                          </div>

                          <div class="row" v-if="entity.cids.length > 0">
                            <div class="col-md-12">
                              <table
                                class="table table-bordered"
                                style="width: 100%"
                              >
                                <thead>
                                  <tr>
                                    <th scope="col" style="width: 100%">
                                      Código e Nome
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="cid in entity.cids"
                                    :key="cid.cid_id"
                                  >
                                    <td>
                                      {{ cid.cid.cid_codigo }} -
                                      {{ cid.cid.cid_nome }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="row" v-else>
                            <div class="col-md-12">Nenhum CID informado</div>
                          </div>
                        </section>
                        <section
                          v-if="entity.tipo_transporte.tipo_solicitacao != 1"
                        >
                          <div class="row mb-2 mt-3">
                            <div class="col-md-12">
                              <div class="b-card-subtitle">
                                Avaliações Clínicas do Paciente
                              </div>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="entity.avaliacoes_clinicas.length > 0"
                          >
                            <div class="col-md-12">
                              <table
                                class="table table-bordered"
                                style="width: 100%"
                              >
                                <thead>
                                  <tr>
                                    <th scope="col" style="width: 52%">
                                      Avaliação
                                    </th>
                                    <th scope="col" style="width: 25%">
                                      Usuário
                                    </th>
                                    <th scope="col" style="width: 23%">
                                      Horário
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="avaliacao in entity.avaliacoes_clinicas"
                                    :key="
                                      moment(avaliacao.datetime).format(
                                        'HHmmss'
                                      )
                                    "
                                  >
                                    <td>
                                      {{
                                        avaliacao.avaliacao_clinica_descricao
                                      }}
                                    </td>
                                    <td>
                                      {{ avaliacao.usuario.usuario_nome }}
                                    </td>
                                    <td>
                                      {{
                                        moment(avaliacao.datetime).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div class="row" v-else>
                            <div class="col-md-12">
                              Nenhuma Avaliação Clínica cadastrada
                            </div>
                          </div>
                        </section>
                      </section>

                      <div class="html2pdf__page-break"></div>
                      <section>
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Posições deste veículo
                            </div>
                          </div>
                        </div>
                        <div class="alert alert-primary" role="alert">
                          Clique sobre a posição desejada para marcar a posição.
                        </div>
                        <div class="col-md-12">
                          <div
                            v-for="posicao in entity.veiculo.posicoes_all"
                            :key="posicao.posicao_veiculo_id"
                          >
                            <div
                              v-if="posicao.posicao.posicao_veiculo_id != 27"
                              class="col-md border-block clickable mt-2"
                              @click="setPosition(entity, posicao)"
                            >
                              <div
                                class="row"
                                :class="
                                  posicao.nesta_posicao
                                    ? 'block-title-active'
                                    : 'block-title'
                                "
                              >
                                <div class="col-md-12">
                                  {{ posicao.posicao.posicao_veiculo_nome }}
                                </div>
                              </div>
                              <div class="row block">
                                <div class="col-md-12">
                                  {{ posicao.hora_posicao }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="mt-0 centered">
            <button class="btn btn-secondary" @click="$router.go(-1)">
              <i class="fas fa-arrow-left"></i> Voltar
            </button>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import html2pdf from "html2pdf.js";

export default {
  name: "imageUpload",
  data() {
    return {
      oculta: false,
      configuracoes: {
        judicial: "",
        sinais_vitais: "",
        recorrencia: 0,
        tipo_destino: 0,
        dificuldade_acesso: 0,
      },
      conselho_classe: [
        {
          nome: "CRM",
          id: "1",
        },
        {
          nome: "CRF",
          id: "2",
        },
        {
          nome: "COREN",
          id: "3",
        },
        {
          nome: "CRO",
          id: "4",
        },
        {
          nome: "CRP",
          id: "5",
        },
        {
          nome: "RG",
          id: "7",
        },
        {
          nome: "Ass Social",
          id: "8",
        },
        {
          nome: "Não informado",
          id: "6",
        },
      ],
      moment: moment,
      isLoading: true,
      fullPage: true,
      page: 1,
      ufs: [],
      cbos: [],
      tipos_cuidado: [],
      produtos: [],
      anexo: {},
      municipios_destino: [],
      municipios_origem: [],
      tipos_transporte: [],
      tipos_domicilio: [],
      tipos_avaliacao_clinica: [],
      tipos_auxilio_locomocao: [],
      tipos_acompanhante: [],
      tipos_posicao: [],
      motivo_transporte: [],
      equipes_disponiveis: [],
      unidade_saude: [],
      status: true,

      entity: {
        paciente: {
          endereco: "",
          bairro: "",
          tipo_peso: "KG",
          peso: "",
          altura: "",
        },
        acompanhante: {},
        solicitante: {},
        unidade: {},
        tipo_transporte: {},
        motivo_transporte: {},
        avaliacoes_clinicas: [],
        cids: [],
        profissional: {},
        usuario_aprovacao: {
          usuario_nome: "",
        },
        classificacao: {},
      },

      avaliacao_clinica: "",
      cid_selecionado: {},
      programacao: {
        tempouso: "00:00",
      },
      movimentacao: {
        veiculo: {
          posicoes: [],
        },
      },
      cids: [],
      cbos: [],
      veiculos_enviados: [],
      historico: [],
      cancelamentos: [],

      configuracoes: {
        judicial: "",
        sinais_vitais: "",
        recorrencia: 0,
        tipo_destino: 0,
      },
      posicao_selecionada: {},
      showCollapse: true,
      showCollapseSolicitacao: true,
      showCollapseCondicoes: true,
    };
  },
  computed: {
    diffIdadeDias() {
      const self = this;

      var dataNascimento = self.entity.paciente.data_nascimento;
      var dataNascimentoObj = new Date(dataNascimento);
      var dataAtual = new Date();
      var diferencaMilissegundos = dataAtual - dataNascimentoObj;
      var diferencaDias = diferencaMilissegundos / (1000 * 60 * 60 * 24);

      return diferencaDias;
    },
    imc() {
      var imc =
        this.entity.paciente.peso /
        (this.entity.paciente.altura * this.entity.paciente.altura);
      return Math.round(imc);
    },
  },
  methods: {
    toggleCollapse() {
      this.showCollapse = !this.showCollapse;
    },
    toggleCollapseSolicitacao() {
      this.showCollapseSolicitacao = !this.showCollapseSolicitacao;
    },
    toggleCollapseCondicoes() {
      this.showCollapseCondicoes = !this.showCollapseCondicoes;
    },
    setPosition(obj, position) {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/movimentar_veiculo";

      if (position.hora_posicao != "-") {
        swal(
          "Cancelado",
          "Já existe uma movimentação gravada para esta posição",
          "info"
        );
        return;
      }

      swal({
        title: "Deseja gravar a data e hora desta posição?",
        text: "O horário da movimentação será o horário atual.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi alterado!", "info");
            break;

          case "confirm":
            self.$loading(true);

            const today = moment().format("YYYY-MM-DD HH:mm:ss");

            var data = {
              veiculo: { envio_veiculo_id: obj.veiculo.envio_veiculo_id },
              tipo: position.posicao.posicao_veiculo_id,
              data: today,
            };

            axios
              .post(api, data)
              .then((response) => {
                self.$loading(false);
                swal("OK!", "O horário foi gravado com sucesso", "success");
                self.getEntity();
                self.getVeiculos();
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
                self.$loading(false);
              });
            break;
        }
      });
    },

    mFDAnexo() {
      const fd = new FormData();
      const anexo = this.$refs.anexo;

      fd.append("transporte_sanitario_id", this.entity.transporte_sanitario_id);
      fd.append("usuario_id", this.$store.state.user.usuario_id);
      fd.append("nome_anexo", this.anexo.nome_anexo);
      fd.append("anexo", anexo ? anexo.files[0] || "" : "");

      if (anexo) {
        anexo.value = "";
      }

      return fd;
    },
    salvarAnexo() {
      const api = this.$store.state.api + "condutor_anexos";
      const fd = this.mFDAnexo();

      this.$http
        .post(api, fd)
        .then((response) => {
          this.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
          this.$loading(false);
        });
    },

    exportToPDF() {
      const self = this;

      self.oculta = true;

      self.$loading(true);

      setTimeout(function () {
        var element = document.getElementById("element-to-convert");
        var opt = {
          margin: 0,
          filename: "FichaDeSolicitacao.pdf",
          image: { type: "png", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
        };
        html2pdf().set(opt).from(element).save();
      }, 2000);

      setTimeout(function () {
        self.$loading(false);
        self.oculta = false;
      }, 5500);
    },

    deletarVeiculo(obj) {
      const self = this;

      swal({
        title: "Deseja deletar o registro?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi excluido!", "info");
            break;

          case "confirm":
            let api = self.$store.state.api + "envio_veiculo/deletar";
            axios
              .post(api, obj)
              .then((response) => {
                swal("Sucesso", "Registro excluído com sucesso!", "success");
                self.getEnvioVeiculo(self.entity.id);
              })
              .catch((error) => {
                swal(
                  "Erro",
                  "Este registro não pode ser deletado, pois já está sendo utilizado no sistema!",
                  "error"
                );
              });
            break;
        }
      });
    },
    editar() {
      const self = this;
      self.$router.push(
        "/solicitacoes/edit/" + self.entity.transporte_sanitario_id
      );
    },
    getConfiguracoes: function () {
      const self = this;
      let api = self.$store.state.api + "configuracoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.configuracoes = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    setHistorico(posicoes) {
      this.historico = posicoes;
    },
    getEntity: function () {
      const self = this;

      let id = self.$route.params.id;
      self.$loading(true);
      let api =
        self.$store.state.api +
        "solicitacoes/" +
        id +
        "?with[]=paciente&with[]=solicitante&with[]=unidade&with[]=avaliacoes_clinicas&with[]=desfechos&with[]=cids&with[]=tipo_transporte&with[]=motivo_transporte&with[]=acompanhante&with[]=origem&with[]=destino&with[]=cancelamento&with[]=usuario_aprovacao&with[]=classificacao&with[]=usuario_cancelamento&with[]=usuario_solicitacao&with[]=usuario_programacao&with[]=usuario_finalizacao&with[]=intercorrencias&with[]=responsavel_programacao&with[]=usuario_rih&with[]=dispositivos&with[]=anexos";

      axios
        .get(api)
        .then((response) => {
          self.entity = response.data.data[0];

          if (self.entity.flag_retorna_origem == "0") {
            self.entity.flag_retorna_origem = false;
          } else {
            self.entity.flag_retorna_origem = true;
          }

          if (self.entity.tipo_transporte.tipo_solicitacao == 0) {
            if (self.entity.paciente.incubadora == 0) {
              self.entity.paciente.incubadora = false;
            } else {
              self.entity.paciente.incubadora = true;
            }

            if (
              self.entity.paciente.bomba_infusora == null ||
              self.entity.paciente.bomba_infusora == "0" ||
              self.entity.paciente.bomba_infusora == 0
            ) {
              self.entity.paciente.flg_bomba_infusora = false;
            } else {
              self.entity.paciente.flg_bomba_infusora = true;
            }

            if (self.entity.paciente.alergias == null) {
              self.entity.paciente.flg_alergias = false;
            } else {
              self.entity.paciente.flg_alergias = true;
            }

            if (
              self.entity.flag_mandado_judicial == "0" ||
              self.entity.flag_mandado_judicial == null
            ) {
              self.entity.flag_mandado_judicial = false;
            } else {
              self.entity.flag_mandado_judicial = true;
            }

            if (
              self.entity.flag_vaga_zero == "0" ||
              self.entity.flag_vaga_zero == null
            ) {
              self.entity.flag_vaga_zero = false;
            } else {
              self.entity.flag_vaga_zero = true;
            }

            // if (self.entity.paciente.covid == 0) {
            //   self.entity.paciente.covid = false;
            // } else {
            //   self.entity.paciente.covid = true;
            // }
          } else {
            self.getTiposCuidado();
            self.getMateriais();
            self.getCuidados();
            self.entity.material = {};
            self.entity.material.materiais = [];
          }

          self.getEnvioVeiculo(self.entity.transporte_sanitario_id);
          // self.getEstadoMunicipioOrigem();
          // self.getEstadoMunicipioDestino();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposCuidado: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_cuidado";

      if (self.tipos_cuidado.length != 0) {
        return;
      }

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.tipos_cuidado = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getMateriais() {
      const self = this;
      let api =
        self.$store.state.api +
        "transporte_sanitario_produtos?with[]=produto&paginated=false&transporte_sanitario_id=" +
        self.entity.transporte_sanitario_id;

      axios
        .get(api)
        .then((response) => {
          var materiais = response.data.data;
          materiais.forEach((element) => {
            var obj = element.produto;
            self.entity.material.materiais.push(obj);
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCuidados() {
      const self = this;
      let api =
        self.$store.state.api +
        "ts_tipo_cuidados?with[]=tipo&paginated=false&transporte_sanitario_id=" +
        self.entity.transporte_sanitario_id;

      axios
        .get(api)
        .then((response) => {
          var cuidados = response.data.data;

          cuidados.forEach((element) => {
            self.tipos_cuidado.forEach((tiac) => {
              if (tiac.tipo_cuidado_id == element.tipo_cuidado_id) {
                tiac.value = true;
              }
            });
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipioOrigem() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.origem.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_origem = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipioDestino() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.destino.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_destino = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    finalizar() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/finalizar";

      swal({
        title: "Deseja finalizar este transporte?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "A solicitação não foi finalizada!", "info");
            break;

          case "confirm":
            axios
              .post(api, {
                transporte_sanitario_id: self.entity.transporte_sanitario_id,
              })
              .then((response) => {
                swal("Sucesso!", "A solicitação foi finalizada!", "success");
                self.$router.push("/programacoes");
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
              });

            break;
        }
      });
    },
    getConfiguracoes: function () {
      const self = this;
      let api = self.$store.state.api + "configuracoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.configuracoes = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getUnidade: function () {
      const self = this;
      let api = self.$store.state.api + "unidade_saude?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.unidade_saude = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProfissional: function () {
      const self = this;
      let api = self.$store.state.api + "profissionais?with[]=cbo";

      axios
        .get(api)
        .then((response) => {
          self.profissional = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getCbo: function () {
      const self = this;
      let api = self.$store.state.api + "cbos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cbos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    approve() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/aprovacao";

      self.$loading(true);

      axios
        .post(api, {
          transporte_sanitario_id: self.entity.transporte_sanitario_id,
        })
        .then((response) => {
          self.$router.push("/aprovacoes");
          self.$loading(false);
          swal("Sucesso!", "A solicitação foi aprovada!", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    cancelar() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/cancelar";

      self.$loading(true);

      axios
        .post(api, {
          transporte_sanitario_id: self.entity.transporte_sanitario_id,
          justificativa: self.entity.justificativa_cancelamento,
          cancelamento_id: self.entity.cancelamento_id,
        })
        .then((response) => {
          $("#modalRejeitar").modal("hide");
          self.$router.push("/solicitacoes");
          self.$loading(false);
          swal("Sucesso!", "A solicitação foi cancelada!", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    salvarProgramacao() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/programacao";

      self.programacao.transporte_sanitario_id =
        self.entity.transporte_sanitario_id;
      self.programacao.data_procedimento = self.entity.data_procedimento;
      self.programacao.equipes_disponiveis = self.equipes_disponiveis;

      axios
        .post(api, self.programacao)
        .then((response) => {
          self.getEntity(self.entity.id);
          self.getEnvioVeiculo(self.entity.id);

          $("#modalProgramacao").modal("hide");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    salvarMovimentacao() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/movimentar_veiculo";

      self.$loading(true);

      axios
        .post(api, self.movimentacao)
        .then((response) => {
          self.getEnvioVeiculo(self.entity.transporte_sanitario_id);
          $("#modalLancamento").modal("hide");
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getTiposAcompanhante: function (id) {
      const self = this;
      let api = self.$store.state.api + "tipo_acompanhante?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos_acompanhante = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEnvioVeiculo: function (id) {
      const self = this;
      let api =
        self.$store.state.api +
        "envio_veiculo?transporte_sanitario_id=" +
        id +
        "&with[]=veiculo&with[]=posicoes";

      axios
        .get(api)
        .then((response) => {
          self.veiculos_enviados = response.data.data;
          self.$loading(false);
          self.isLoading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
          self.isLoading = false;
        });
    },
    deletePosition: function () {
      const self = this;

      let api =
        self.$store.state.api +
        "movimentacao_veiculo/" +
        self.posicao_selecionada.id;

      self.$loading(true);
      axios
        .patch(api, {
          status: 0,
          justificativa_historico:
            self.posicao_selecionada.justificativa_historico,
        })
        .then((response) => {
          self.getEnvioVeiculo(self.entity.transporte_sanitario_id);
          self.$loading(false);
          self.isLoading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
          self.isLoading = false;
        });
    },

    selectUnidadeDestino() {
      const self = this;

      if (self.entity.destino.municipio_id == null) {
        return;
      }

      self.entity.destino.cep = self.entity.destino.unidade_saude_id.cep;
      self.entity.destino.endereco =
        self.entity.destino.unidade_saude_id.endereco;
      self.entity.destino.numero = self.entity.destino.unidade_saude_id.numero;
      self.entity.destino.ponto_refencia =
        self.entity.destino.unidade_saude_id.ponto_refencia;
      // self.entity.destino.complemento =
      //   self.entity.destino.unidade_saude_id.complemento;
      self.entity.destino.bairro = self.entity.destino.unidade_saude_id.bairro;
      self.entity.destino.estabelecimento_nome =
        self.entity.destino.unidade_saude_id.unidade_saude_nome;

      let api =
        self.$store.state.api +
        "municipios?municipio_id=" +
        self.entity.destino.unidade_saude_id.municipio_id;

      axios
        .get(api)
        .then((response) => {
          self.entity.destino.estado_id = response.data.data[0].uf_id;
          self.getEstadoMunicipioDestino();
          self.entity.destino.municipio_id = response.data.data[0].municipio_id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposAuxilioLocomocao: function (id) {
      const self = this;
      let api =
        self.$store.state.api + "tipo_auxilio_locomocao?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos_auxilio_locomocao = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCancelamentos: function (id) {
      const self = this;
      let api = self.$store.state.api + "cancelamentos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cancelamentos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposPosicao: function () {
      const self = this;
      let api =
        self.$store.state.api + "posicao_veiculo?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_posicao = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUFs: function () {
      const self = this;
      let api =
        self.$store.state.api + "ufs?orderByAsc=uf_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.ufs = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getVeiculosDisponiveis() {
      const self = this;
      let api = self.$store.state.api + "equipes_disponiveis";

      if (
        self.programacao.tempouso.includes("_") ||
        self.programacao.tempouso == ""
      ) {
        return;
      }

      self.$loading(true);

      self.programacao.transporte_sanitario_id =
        self.entity.transporte_sanitario_id;
      self.programacao.data_procedimento = self.entity.data_procedimento;

      axios
        .post(api, self.programacao)
        .then((response) => {
          self.equipes_disponiveis = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getTiposDomicilio: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_domicilio";

      axios
        .get(api)
        .then((response) => {
          self.tipos_domicilio = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    // self.getConfiguracoes();
    // self.getProfissional();
    // self.getUnidade();
    let id = self.$route.params.id;
    if (id) {
      self.change_password = false;
      self.getConfiguracoes();
      // self.getTiposAcompanhante();
      self.getTiposAuxilioLocomocao();
      self.getTiposDomicilio();
      self.getTiposPosicao();
      // self.getUFs();
      // self.getCancelamentos();
      self.getEntity(id);
    }
  },
  components: {
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.b-card-subtitle {
  font-size: 18px;
  font-weight: bold;
}

.btn-edit {
  position: fixed;
  width: 10%;
  /* height: 25px; */
  bottom: 40px;
  right: 60%;
  background-color: #3387f4;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
}

.btn-edit:hover {
  background-color: #2781f7;
  color: #fff;
  box-shadow: 5px 5px 7px #888;
}

.btn-info {
  position: fixed;
  width: 15%;
  /* height: 25px; */
  bottom: 40px;
  right: 60%;
  background-color: #5f9ef1;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #6a6a6a;
}

.btn-approve {
  position: fixed;
  width: 10%;
  /* height: 25px; */
  bottom: 40px;
  right: 45%;
  background-color: #25d366;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
}

.btn-approve:hover {
  background-color: #0daa47;
  color: #fff;
}

.btn-reprove {
  position: fixed;
  width: 10%;
  /* height: 25px; */
  bottom: 40px;
  right: 30%;
  background-color: #d3252e;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
}

.btn-reprove:hover {
  background-color: #af151d;
  color: #fff;
}

/* progress2 Tracker v2 */

ol.progress2[data-steps="2"] li {
  width: 49% !important;
  z-index: 99;
}

ol.progress2[data-steps="3"] li {
  width: 33% !important;
  z-index: 99;
}

ol.progress2[data-steps="4"] li {
  width: 24% !important;
  z-index: 99;
}

ol.progress2[data-steps="5"] li {
  width: 19% !important;
  z-index: 99;
}

ol.progress2[data-steps="6"] li {
  width: 16% !important;
  z-index: 99;
}

ol.progress2[data-steps="7"] li {
  width: 14% !important;
  z-index: 99;
}

ol.progress2[data-steps="8"] li {
  width: 12% !important;
  z-index: 99;
}

ol.progress2[data-steps="9"] li {
  width: 11% !important;
  z-index: 99;
}

.progress2 {
  width: 100% !important;
  list-style: none !important;
  list-style-image: none !important;
  margin: 20px 0 20px 0 !important;
  padding: 0 !important;
  z-index: 99;
}

.progress2 li {
  float: left !important;
  text-align: center !important;
  position: relative !important;
  z-index: 99;
}

.progress2 .name {
  display: block !important;
  text-align: center !important;
  margin-bottom: 1em !important;
  color: black !important;
  opacity: 0.3 !important;
  z-index: 99;
}

.progress2 .step {
  color: black !important;
  border: 3px solid silver !important;
  background-color: silver !important;
  border-radius: 50% !important;
  line-height: 1.5 !important;
  width: 2em !important;
  height: 2em !important;
  display: inline-block !important;
  z-index: 0 !important;
  font-weight: bold;
}

.progress2 .step-description {
  position: absolute;
  top: 80px;
  left: 30%;
}

.progress2 .step span {
  opacity: 0.3 !important;
}

.progress2 .active .name,
.progress2 .active .step span {
  opacity: 1 !important;
}

.progress2 .step:before {
  content: "" !important;
  display: block !important;
  background-color: silver !important;
  height: 0.4em !important;
  width: 50% !important;
  position: absolute !important;
  bottom: 0.6em !important;
  left: 0 !important;
  z-index: -1 !important;
}

.progress2 .step:after {
  content: "" !important;
  display: block !important;
  background-color: silver !important;
  height: 0.4em !important;
  width: 50% !important;
  position: absolute !important;
  bottom: 0.6em !important;
  right: 0 !important;
  z-index: -1 !important;
}

.progress2 li:first-of-type .step:before {
  display: none !important;
}

.progress2 li:last-of-type .step:after {
  display: none !important;
}

.progress2 .done .step,
.progress2 .done .step:before,
.progress2 .done .step:after,
.progress2 .active .step,
.progress2 .active .step:before {
  background-color: yellowgreen !important;
}

.progress2 .done .step,
.progress2 .active .step {
  border: 3px solid yellowgreen !important;
}

.deleted {
  text-decoration: line-through;
  color: #a1a1a1;
}

tr.obesidade1 {
  color: #fff;
  font-weight: bold;
  background-color: #ff4500;
}

tr.obesidade2 {
  color: #fff;
  font-weight: bold;
  background-color: #ff0000;
}

tr.obesidade3 {
  color: #fff;
  font-weight: bold;
  background-color: #e30505;
}

.classificacao {
  width: 35px;
  height: 35px;
  border-radius: 3px;
}

.break {
  page-break-before: always;
}

@media print {
  .no-print {
    display: none;
  }
}

.button-tripulante {
  border: solid #bebebe 1px;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
}

.block {
  background-color: white;
  border-radius: 3px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.block-title {
  background-color: #15488a;
  color: white;
  text-align: center;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
}

.block-title-active {
  background-color: #15488a;
  color: white;
  text-align: center;
  font-weight: bold;
  padding-top: 4px;
  padding-bottom: 4px;
  animation: animate 1.5s linear infinite;
}

.border-block {
  border: 1px #15488a solid;
  border-radius: 3px;
  margin: 7px;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.collapse-container {
  margin: 20px;
}

.collapse-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.collapse-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.collapse-content p {
  margin: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}
</style>
