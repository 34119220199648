import Crud from '../views/Checklist/Crud'
import View from '../views/Checklist/View'

let routes = {
    path: '/checklists',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [

        {
            path: '/checklists/view/:id?',
            name: 'ViewChecklist',
            component: View
        },
        {
            path: '/checklists',
            name: 'list-checklists',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Checklists',
                breadcrumb: [
                    {
                        text: 'Checklists',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
