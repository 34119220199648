<template>
  <div>
    <div class="row">
      <div class="col-12 mb-1" align="right">
        <button
          class="btn btn-success"
          data-toggle="modal"
          data-target="#modalExport"
        >
          Exportar Solicitações em Excel
        </button>
        <div class="ibox-title" style="padding: 16px">
          <h3>Finalizados e Cancelados</h3>
          <div
            class="modal fade"
            id="modalExport"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalExportTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Exportar
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-content modal-lg mt-1">
                  <div
                    class="alert alert-warning"
                    role="alert"
                    v-if="!filters.data_inicio && !filters.data_fim"
                  >
                    Para exportar todos os registros de finalizados e
                    cancelados, basta não definir nenhum intervalo de datas e
                    proceder com a exportação.
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div class="d-flex justify-content-between">
                    <div class="col-md-6 mb-1">
                      <label>Data de Ínicio</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="filters.data_inicio"
                      />
                    </div>
                    <div class="col-md-6 mb-1">
                      <label>Data do Fim</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="filters.data_fim"
                      />
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="exportToExcel"
                  >
                    Exportar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'checklistsCrud'"
          crudName="Checklists"
          newText="Novo"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="false"
          :enableEdit="false"
          :enableDelete="false"
          :enableView="true"
          :enableReset="false"
          :dataForm="dataForm"
          :customFilters="customFilters"
          @filtered_dg="filtered_dg"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      filters: { data_inicio: null },
      customFilters: [
        {
          name: "data_gravacao_f",
          type: "calendar",
          call: "filtered_dg",
        },
      ],
      data_gravacao: "",
      table: "checklistsCrud",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: [
        "checklist_id",
        "data_gravacao_f",
        "veiculo_id",
        "placa_veiculo",
        "usuario_id",
        "status",
        "actions",
      ],
      tableData: [
        "checklist_id",
        "created_at_f",
        "placa_veiculo",
        "data_gravacao_f",
        "veiculo_id",
        "usuario_id",
        "status",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: [
          "checklist_id",
          "created_at_f",
          "veiculo_id",
          "placa_veiculo",
          "usuario_id",
          "status",
        ],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          checklist_id: "ID",
          data_gravacao_f: "Data Criado",
          veiculo_id: "Veículo",
          placa_veiculo: "Placa do veículo",
          usuario_id: "Condutor",
          status: "Status do Checklist",
          actions: "Ações",
        },
        sortable: ["checklist_id", "veiculo_id", "usuario_id", "status"],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status: [
            {
              id: 3,
              text: "Finalizado",
            },
            {
              id: 2,
              text: "Em manutenção",
            },
            {
              id: 1,
              text: "Em acompanhamento",
            },
            {
              id: 0,
              text: "Não verificado",
            },
          ],
        },
        templates: {
          veiculo_id: function (h, row, index) {
            return row.veiculo ? row.veiculo.nome_veiculo : "Sem Nome";
          },
          placa_veiculo: function (h, row, index) {
            return row.veiculo
              ? row.veiculo.placa_veiculo
              : "Placa não cadastrada";
          },
          usuario_id: function (h, row, index) {
            return row.usuario ? row.usuario.usuario_nome : "Sem Nome";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          console.log(this.$parent.$parent);

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.data_gravacao != null &&
            this.$parent.$parent.$parent.data_gravacao != ""
          ) {
            requestData.params.data_gravacao =
              this.$parent.$parent.$parent.data_gravacao;
          }

          requestData.params.query = "";
          requestData.params.with = ["veiculo", "usuario"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "checklists",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    filtered_dg(date) {
      this.data_gravacao = date;
    },

    exportToExcel() {
      const self = this;
      const dataInicio = self.filters.data_inicio;
      const dataFim = self.filters.data_fim;

      if (dataInicio || dataFim) {
        if (dataInicio && dataFim) {
          var url = `${self.$store.state.url}checklists/exportar?data_inicio=${dataInicio}&data_fim=${dataFim} 23:59:59`;
          window.open(url, "_blank").focus();
        } else {
          self.$message(
            null,
            "Por favor, preencha ambos os campos de data.",
            "error"
          );
          return;
        }
      } else {
        window.open(url, "_blank").focus();
      }
    },

    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    query: function (query) {
      let columns = {
        checklist_id: "checklist_id",
        usuario_id: "usuario[usuario_nome]",
        veiculo_id: "veiculo[nome_veiculo]",
        placa_veiculo: "veiculo[placa_veiculo]",
        status: "status",
        data_gravacao: "data_gravacao",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>