<template>
  <div>
    <div class="row">
      <div class="col-12 centered bold">Checklist de Ambulância</div>
      <div class="col-12 mt-2">
        <span class="bold">Veículo:</span> {{ veiculo.nome_veiculo }}
      </div>
      <div class="col-12 mt-1">
        <span class="bold">Placa:</span>
        {{ veiculo.placa_veiculo }}
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12">
        <label>Km Atual</label>
        <input
          type="tel"
          v-model="checklist.km_atual"
          class="form-control"
          disabled
        />
      </div>
      <div class="col-12 mt-1">
        <label>Ambulância Lavada?</label>
        <select
          class="form-control"
          v-model="checklist.ambulancia_lavada"
          disabled
        >
          <option value=""></option>
          <option value="SIM">SIM</option>
          <option value="NÃO">NÃO</option>
        </select>
      </div>
      <div class="col-12 mt-1">
        <label>Nível do Combustível</label>
        <select
          class="form-control"
          v-model="checklist.nivel_combustivel"
          disabled
        >
          <option value="Vazio/Reserva">Vazio/Reserva</option>
          <option value="1/4">1/4</option>
          <option value="1/2">1/2</option>
          <option value="3/4">3/4</option>
          <option value="Cheio">Cheio</option>
        </select>
      </div>
      <!-- <div class="col-12 mt-1">
        <label>Houve abastecimento? quantos litros foram abastecidos?</label>
        <input
          type="tel"
          class="form-control"
          v-model="checklist.litros_abastecidos"
          disabled
        />
      </div>
      <div class="col-12 mt-1">
        <label>Data/Hora Abastecimento</label>
        <input
          type="datetime-local"
          class="form-control"
          v-model="checklist.data_abastecimento"
          disabled
        />
      </div> -->
      <div class="col-md-12" style="padding: 35px">
        <div v-for="group in group_checklist" :key="group.id" class="row">
          <div class="col-md-12 title-group mt-1">{{ group.titulo }}</div>
          <div
            class="col-md-12"
            v-for="item in filteredGroup(group.grupo_checklist_id)"
            :key="item.id"
          >
            <div class="row mt-1" style="border-bottom: 1px solid #eaeaea">
              <div class="col-6" style="margin-top: 10px">
                {{ item.titulo }}
              </div>
              <!-- Pergunta do tipo texto -->
              <div class="col-6 mb-1" v-if="item.tipo_pergunta == 2">
                <input
                  type="text"
                  class="form-control"
                  v-model="item.value"
                  disabled
                />
              </div>

              <!-- Pergunta do tipo número -->
              <div class="col-6 mb-1" v-if="item.tipo_pergunta == 3">
                <input
                  type="tel"
                  class="form-control"
                  v-model="item.value"
                  disabled
                />
              </div>

              <!-- Pergunta do tipo alternativa -->
              <div class="col-6 mb-1" v-if="item.tipo_pergunta == 1">
                <select class="form-control" v-model="item.value" disabled>
                  <option value=""></option>
                  <option
                    v-for="resposta in respostas_checklist"
                    :key="resposta.id"
                    :value="resposta.titulo"
                  >
                    {{ resposta.titulo }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="sectionDraw" ref="parentElement">
      <div class="row">
        <div class="col-12 mt-2">
          <div class="alert alert-info centered" role="alert">
            Aqui você visualiza as marcações que foram feitas pelo condutor,
            posicione o mouse sobre o quadrado verde para ver a observação
          </div>
        </div>
        <div class="col-md-12">
          <div class="draw"></div>
        </div>
      </div>
    </section>
    <div class="row mt-2" v-if="photos.length > 0">
      <div class="col-12 centered bold">Imagens de Evidência</div>
    </div>

    <div class="row" v-if="photos.length > 0">
      <div class="col-12 mt-3">
        <div class="row justify-content-center">
          <div v-for="(photo, index) in photos" :key="index" class="col-2">
            <img
              :src="photo.foto"
              alt="Imagem"
              style="
                max-width: 150px;
                max-height: 150px;
                border: 2px solid #222;
                border-radius: 5px;
                margin-bottom: 10px;
                cursor: pointer;
              "
              data-toggle="modal"
              data-target="#modalImagem"
              @click="imagem_modal = photo"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Alterar Status -->
    <div
      class="modal fade"
      id="modalAlterar"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAlterarTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Alterar Status
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <label>Status</label>
                <select class="form-control" id="statusChecklistSelect">
                  <option :value="0">Não verificado</option>
                  <option :value="1">Em acompanhamento</option>
                  <option :value="2">Em manutenção</option>
                  <option :value="3">Finalizado</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="alterarStatus"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Imagem -->
    <div
      class="modal fade"
      id="modalImagem"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalImagemTitle"
      aria-hidden="true"
      v-if="imagem_modal.foto != ''"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Visualizar Imagem
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img :src="imagem_modal.foto" alt="" width="750" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-1">
        <label>Observação: </label>
        <textarea
          v-model="checklist.observacao"
          class="form-control"
          id=""
          cols="20"
          rows="3"
          disabled
        ></textarea>
      </div>
      <div class="col-12 mt-1">
        <label>Status do Checklist</label>
        <select class="form-control" v-model="checklist.status" disabled>
          <option value="0">Não verificado</option>
          <option value="1">Em acompanhamento</option>
          <option value="2">Em manutenção</option>
          <option value="3">Finalizado</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mt-1">
        <button
          class="col-md-12 btn btn-add mr-2 mt-1"
          data-toggle="modal"
          data-target="#modalAlterar"
        >
          Alterar Status do Checklist
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { createCanvas, loadImage } from "canvas";

export default {
  data() {
    return {
      page: 1,
      exists: false,
      equipe: {},
      tripulacao: {},
      checklist: {},
      veiculo: {},
      points: [],
      group_checklist: [],
      itens_checklist: [],
      respostas_checklist: [],
      pin_iterator: 0,
      point: {},
      photos: [],
      isDragging: false,
      fuelWidth: 70,
      imagem_modal: {
        foto: "",
      },
    };
  },
  computed: {},
  methods: {
    save(is) {
      const self = this;
      let api = self.$store.state.api + "checklists/save";

      self.$loading(true);

      axios
        .post(api, {
          itens: self.itens_checklist,
          veiculo: self.veiculo,
          fotos: self.photos,
          points: self.points,
          checklist: self.checklist,
          page: self.page,
        })
        .then((response) => {
          if (is == 1) {
            self.page++;
            self.$loading(false);
            return;
          }
          self.$loading(false);
          this.$router.push("/condutor/index");
          self.$message("Sucesso", "Checklist finalizado", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    filteredGroup(id) {
      return this.itens_checklist.filter(
        (item) => item.grupo_checklist_id == id
      );
    },
    view(id) {
      const self = this;
      this.$router.push("/visualizar-solicitacao/" + id);
    },
    getEntity: function (id) {
      const self = this;
      let api =
        self.$store.state.api +
        "checklists/" +
        id +
        "?with[]=usuario&with[]=veiculo&with[]=points&with[]=photos&with[]=itens";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.equipe = response.data.data[0].equipe;
          self.veiculo = response.data.data[0].veiculo;

          self.points = response.data.data[0].points;

          var chk = response.data.data[0];

          self.checklist.checklist_id = chk.checklist_id;
          self.checklist.km_atual = chk.km_atual;
          self.checklist.ambulancia_lavada = chk.ambulancia_lavada;
          self.checklist.nivel_combustivel = chk.nivel_combustivel;
          self.checklist.litros_abastecidos = chk.litros_abastecidos;
          self.checklist.data_abastecimento = chk.data_abastecimento;
          self.checklist.itens = chk.itens;
          self.checklist.observacao = chk.observacao;
          self.checklist.status = chk.status;
          self.photos = chk.photos;

          self.$loading(false);

          if (self.veiculo && !self.exists) {
            self.getGroupChecklist();
            self.getItemsChecklist();
            self.getResponsesChecklist();
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    getGroupChecklist() {
      const self = this;
      let api = self.$store.state.api + "groupchecklists?paginated=false";

      axios
        .get(api, self.filtro)
        .then((response) => {
          self.group_checklist = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    getItemsChecklist() {
      const self = this;
      let api = self.$store.state.api + "itemchecklists?paginated=false";

      self.$loading(true);

      axios
        .get(api, self.filtro)
        .then((response) => {
          self.itens_checklist = response.data.data;

          self.checklist.itens.forEach((element) => {
            self.itens_checklist.forEach((item) => {
              if (item.item_checklist_id == element.item_checklist_id) {
                item.value = element.value;
              }
            });
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    alterarStatus() {
      const self = this;
      let api = self.$store.state.api + "checklists/alterar-status";

      swal({
        title: "Deseja alterar status?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O status não foi alterado!", "info");
            break;

          case "confirm":
            const selectElement = document.getElementById(
              "statusChecklistSelect"
            );
            const novoStatus = parseInt(selectElement.value, 10);
            axios
              .post(api, {
                checklist_id: self.checklist.checklist_id,
                status: novoStatus,
              })
              .then((response) => {
                $("#modalAlterar").modal("hide");
                self.$loading(false);
                swal("Sucesso!", "O status foi alterado!", "success");
                this.$router.push("/checklists");
              })
              .catch((error) => {
                self.$loading(false);
                self.$message(null, error.response.data, "error");
              });

            break;
        }
      });
    },

    getResponsesChecklist() {
      const self = this;
      let api = self.$store.state.api + "respostachecklists?paginated=false";

      self.$loading(true);

      axios
        .get(api, self.filtro)
        .then((response) => {
          self.respostas_checklist = response.data.data;
          self.verifyPoints();
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    verifyPoints() {
      const self = this;

      setTimeout(function () {
        self.points.forEach((element) => {
          self.pinImage(element.x, element.y, element.nota);
        });
      }, 1000);
    },

    pinImage(mouseX, mouseY, title) {
      const parentOffset = $("#sectionDraw").offset();

      var color = "#00ff45";
      var size = "25px";
      var div = document.createElement("div");
      div.setAttribute("id", "pin" + this.pin_iterator);
      div.setAttribute("title", title);
      div.setAttribute("data-placement", "top");
      div.setAttribute("data-toggle", "tooltip");
      div.style.position = "absolute";

      div.style.top =
        parseFloat(mouseY) + parseFloat(parentOffset.top) - 160 + "px";

      div.style.left = parseFloat(mouseX) + 30 + "px";

      div.style.width = size;
      div.style.height = size;
      div.style.backgroundColor = color;

      document.getElementById("sectionDraw").appendChild(div);
      this.pin_iterator++;
      $('[data-toggle="tooltip"]').tooltip();
    },
  },
  components: { Loading },
  props: {},
  mounted() {
    const self = this;

    self.getEntity(self.$route.params.id);

    setTimeout(function () {
      self.user = self.$store.state.user;
      $("body").css("zoom", "100%");
    }, 200);
  },
};
</script>
<style scoped>
/* CUSTOMIZACOES VUE LOADING */
.custom-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 128, 0);
  backdrop-filter: blur(3px);
  opacity: 0.98;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.custom-loading-spinner {
  width: 100px;
  height: 100px;
  z-index: 10000;
}

/* CUSTOMIZACOES INPUTS */
.custom-input {
  border-radius: 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control:focus {
  z-index: 3;
}

.custom-input-container {
  position: relative;
}

.custom-input {
  padding: 0.375rem 0.75rem;
  height: 2.714rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15);
  width: 100%;
}

.custom-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.botao-cadastrar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.sem-registros {
  position: fixed;
  bottom: 50%;
  width: 100%;
  /* padding-left: 10px;
  padding-right: 10px; */
}

span {
  font-weight: normal;
}

.pisca {
  animation: animate 2.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.draw {
  background-image: url("~@/assets/images/checklist_ambulancia.png");
  background-repeat: no-repeat;
  width: 370px !important;
  background-size: cover;
  height: 530px;
  border-style: solid;
}

.title-group {
  background-color: #003163;
  color: white;
  width: 100% !important;
  font-size: 16px;
  font-weight: bold;
}

.btn-addimg {
  border: 2px solid #003163;
  padding: 0;
}
.btn-block {
  margin-top: 25px;
  width: 170px;
  padding: 15px;
}

.fuel-indicator {
  width: 200px;
  height: 30px;
  border: 1px solid #000;
  position: relative;
  cursor: pointer;
}

.marks-container {
  display: flex;
  height: 100%;
}

.mark {
  height: 100%;
  flex-grow: 1;
  background-color: #000;
  opacity: 0.5;
}

.fuel-level {
  height: 100%;
  background-color: #36a2eb;
  position: absolute;
  top: 0;
  left: 0;
}
</style>