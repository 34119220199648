<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                {{ item_checklist.item_checklist_id ? "Editar" : "Novo" }}
                Item de Checklist
              </div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Nome:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="item_checklist.titulo"
                          placeholder=""
                        />
                      </div>

                      <div class="col-md-3">
                        <label for="Name"> Grupo de Checklist: </label>
                        <select
                          class="form-control"
                          v-model="item_checklist.grupo_checklist_id"
                        >
                          <option
                            v-for="grupo in group_checklists"
                            :key="grupo.grupo_checklist_id"
                            :value="grupo.grupo_checklist_id"
                          >
                            {{ grupo.titulo }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-3">
                        <label for="Name"> Tipo de Pergunta:</label>
                        <select
                          class="form-control"
                          v-model="item_checklist.tipo_pergunta"
                        >
                          <option
                            v-for="tipo in tipos_resposta"
                            :key="tipo.id"
                            :value="tipo.id"
                          >
                            {{ tipo.titulo }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="col-md-12 bold mt-3"
                        v-if="item_checklist.tipo_pergunta == 1"
                      >
                        Itens do checklist:
                      </div>
                      <div
                        class="col-md-12 mt-3 mb-3"
                        v-if="item_checklist.tipo_pergunta == 1"
                      >
                        <button
                          class="btn btn-primary"
                          type="button"
                          @click="item_checklist.itens.push({ titulo: '' })"
                        >
                          Adicionar Item
                        </button>
                      </div>
                      <div
                        class="col-md-12"
                        v-if="item_checklist.tipo_pergunta == 1"
                      >
                        <div
                          class="row mb-1"
                          v-for="(item, index) in item_checklist.itens"
                          :key="item.id"
                        >
                          <div class="col-md-11">
                            <input
                              type="text"
                              class="form-control"
                              v-model="item.titulo"
                            />
                          </div>
                          <div class="col-md-1">
                            <button
                              class="btn btn-crud delete"
                              type="button"
                              @click="item_checklist.itens.splice(index, 1)"
                            >
                              <i class="fas fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link
                        to="/itemchecklists"
                        class="btn btn-back mr-1"
                      >
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  data() {
    return {
      item_checklist: {
        titulo: "",
        grupo_checklist_id: "",
        itens: [],
      },
      group_checklists: [],
      tipos_resposta: [
        { id: 1, titulo: "Alternativa" },
        { id: 2, titulo: "Texto" },
        { id: 3, titulo: "Número" },
      ],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "itemchecklists";

      if (self.item_checklist.item_checklist_id) {
        self.item_checklist._method = "PUT";
        api += "/" + self.item_checklist.item_checklist_id;
      }

      axios
        .post(api, self.item_checklist)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/itemchecklists");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getItemChecklist: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "itemchecklists/" + id + "?with[]=itens";

      axios
        .get(api)
        .then((response) => {
          self.item_checklist = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getGrupoChecklist: function () {
      const self = this;
      let api = self.$store.state.api + "groupchecklists?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.group_checklists = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    self.getGrupoChecklist();

    let id = self.$route.params.id;
    if (id) {
      self.getItemChecklist(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>